import React, { useEffect, useRef, useState } from "react";
import {
  HeaderButtonContainer,
  HeaderContainer,
  HeaderListContainer,
  HeaderMainContainer,
  MenuDropDown,
  MenuDropDownContainer,
  MenuItem,
  MobileNavBarContainer,
  NavBar,
} from "../style/header.style";
import logo from "../assets/logo/4.svg";
import darkLogo from "../assets/logo/3.svg";
import { CommonString } from "../utils/CommonString";
import UseHover from "./UseHover";
import MagneticHeaderButton from "./Button";
import DropDown from "../assets/icon/DropDown.svg";
import { useDispatch, useSelector } from "react-redux";
import {
  onChangeIsOpenMobileNavBar,
  onChangeIsOpenServiceDropDown,
} from "../store/CommonStore";
import { UseScroll } from "./UseScroll";
import Lottie from "lottie-react";
import menuAnimation from "../assets/icon/menuAnimation.json";
import DownArrow from "../assets/icon/DownArrow.svg";
import { Link, useLocation } from "react-router-dom";
import ServiceDropDown from "./ServiceDropDown";

export default function Header() {
  const dispatch = useDispatch();
  const location = useLocation();
  const [hoverRef] = UseHover();
  const parent = useRef(0);
  const lottieRef = useRef();
  const { scrollDirection } = UseScroll();
  const isOpen = useSelector(
    (state) => state.CommonStore.is_open_service_dropDown
  );
  const lightHeader = useSelector((state) => state.CommonStore.lightHeader);
  const [isOpenModal, setIsOpen] = useState(false);
  useEffect(() => {
    lottieRef.current.pause();
  }, []);
  useEffect(() => {
    onScrollDown();
  }, [scrollDirection !== "down" || window.scrollY === 0]);

  const handleClose = () => {
    dispatch(onChangeIsOpenServiceDropDown(false));
    setIsOpen(false);
    dispatch(onChangeIsOpenMobileNavBar(false));
    toggleMenu();
  };

  const toggleMenu = () => {
    window.scrollTo({ top: 0, behavior: "smooth" });
    dispatch(onChangeIsOpenMobileNavBar(!isOpenModal));
    setIsOpen(!isOpenModal);
    if (isOpenModal) {
      lottieRef.current.setDirection(-1);
    } else {
      lottieRef.current.setDirection(1);
    }
    lottieRef.current.play();
  };
  function onClickScrollTop() {
    window.scrollTo({ top: 0, behavior: "smooth" });
  }
  function onScrollDown() {
    dispatch(onChangeIsOpenServiceDropDown(false));
  }

  return (
    <>
      <div className="viewOnlyInMobile">
        <MobileNavBarContainer>
          <img alt="" src={darkLogo} />
          <Lottie
            lottieRef={lottieRef}
            loop={false}
            autoPlay={false}
            animationData={menuAnimation}
            speed={0.1}
            onClick={() => {
              setIsOpen(!isOpenModal);
              toggleMenu();
            }}
          />
        </MobileNavBarContainer>
        <NavBar isOpenModal={isOpenModal}>
          <hr />
          <Link to={"/"} onClick={() => toggleMenu()}>
            <MenuItem activeStatus={location.pathname === "/"}>
              {CommonString.home}
            </MenuItem>
          </Link>
          <Link to={"/About"} onClick={() => toggleMenu()}>
            <MenuItem activeStatus={location.pathname === "/About"}>
              {CommonString.about}
            </MenuItem>
          </Link>
          <Link to={"/ITConsulting"} onClick={() => toggleMenu()}>
            <MenuItem activeStatus={location.pathname === "/ITConsulting"}>
              {CommonString.it_consulting}
            </MenuItem>
          </Link>
          <Link to={"/StaffAugmentation"} onClick={() => toggleMenu()}>
            <MenuItem activeStatus={location.pathname === "/StaffAugmentation"}>
              {CommonString.staff_augmentation}
            </MenuItem>
          </Link>
          <Link to={"/contact"} onClick={() => toggleMenu()}>
            <MenuItem activeStatus={location.pathname === "/contact"}>
              {CommonString.contact}
            </MenuItem>
          </Link>
        </NavBar>
      </div>
      <HeaderMainContainer
        className="viewOnlyInLaptop"
        isVisible={scrollDirection === "down" || window.scrollY === 0}
      >
        <HeaderContainer
          isVisible={scrollDirection === "down" || window.scrollY === 0}
          className="text"
          lightHeader={lightHeader}
        >
          <Link to={"/"} onClick={() => onClickScrollTop()}>
            <img alt="" src={lightHeader ? logo : darkLogo} />
          </Link>
          <div style={{ display: "flex" }}>
            <HeaderListContainer lightHeader={lightHeader}>
              <Link to={"/"} onClick={() => onClickScrollTop()}>
                <MenuItem activeStatus={location.pathname === "/"}>
                  {CommonString.home}
                </MenuItem>
              </Link>
              <Link to={"/About"} onClick={() => onClickScrollTop()}>
                <MenuItem activeStatus={location.pathname === "/About"}>
                  {CommonString.about}
                </MenuItem>
              </Link>
              <MenuDropDownContainer open={isOpen}>
                <MenuItem
                  activeStatus={
                    location.pathname === "/ITConsulting" ||
                    location.pathname === "/StaffAugmentation"
                  }
                  onClick={(e) => {
                    e.stopPropagation();
                    dispatch(onChangeIsOpenServiceDropDown(!isOpen));
                  }}
                >
                  {CommonString.services}
                  <img src={lightHeader ? DropDown : DownArrow} alt="" />
                  <div class="arrow"></div>
                </MenuItem>
              </MenuDropDownContainer>
            </HeaderListContainer>
            <HeaderButtonContainer>
              <MagneticHeaderButton
                onClick={() => onClickScrollTop()}
                isLightBackground={lightHeader}
                text={"Let’s Strategize"}
                ref={hoverRef}
                parent={parent}
              />
            </HeaderButtonContainer>
          </div>
          <ServiceDropDown
            // is_visible={scrollDirection === "down" || window.scrollY === 0}
            open={isOpen}
            onClose={handleClose}
          />
        </HeaderContainer>
      </HeaderMainContainer>
    </>
  );
}
