import gsap from "gsap";
import React, { forwardRef, useState } from "react";
import { Oval } from "react-loader-spinner";
import styled from "styled-components";
import { Color } from "../utils/Color";
import { ButtonForMobileView } from "../style/common.style";
import { Link } from "react-router-dom";

const Style = styled.a`
  position: relative;
  display: inline-flex;
  justify-content: center;
  padding: 10px 30px;
  font-size: 1.2vmax;
  font-weight: 500;
  line-height: 1.25;
  letter-spacing: 0;
  letter-spacing: 0.025em;
  color: black;
  background: ${(props) =>
    props.isLightBackground ? Color.background : Color.primary_color};
  /* border: 1px solid
    ${(props) =>
    props.lightHeader ? Color.background : Color.primary_color}; */
  border-radius: 50px;
  user-select: none;
  cursor: ${(props) => (props.$disable ? "" : "pointer")};
  width: auto;
  overflow: hidden;
  transition: all 0.75s cubic-bezier(0.52, -0.01, 0.17, 0.75);
  @keyframes changeButtonScale {
    0% {
      transform: translate3d(0);
    }
    100% {
      transform: translate3d(var(--x, 0px), var(--y, 0px), 0);
    }
  }
  @keyframes transitionButton {
    0% {
      transform: scale(1) translate3d(0);
    }
    50% {
      transform: scale(1.08)
        translate3d(calc(var(--x, 0px) / 2), calc(var(--y, 0px) / 2), 0);
    }
    100% {
      transform: scale(1) translate3d(var(--x, 0px), var(--y, 0px), 0);
    }
  }
  animation-duration: 0.3s;
  animation-timing-function: ease;
  animation-fill-mode: forwards;
  animation-direction: alternate;
`;

export const TextContainer = styled.div`
  font-size: 16px;
  font-weight: 600;
  letter-spacing: 0;
  white-space: nowrap;
  height: max-content;
  width: max-content;
  color: ${(props) =>
    !props.isLightBackground ? Color.background : Color.primary_color};
  transition: color 0.3s ease;
  display: flex;
  align-items: center;
  font-weight: bold;
  svg {
    margin-left: 10px;
  }
  /* padding: 0 1px; */
`;
const FillAnimation = styled.div`
  background: ${(props) =>
    props.isLightBackground ? Color.secondary_color : Color.secondary_color};
  height: 400px;
  width: 400px;
  transform: translate(-50%, -50%) scale(0);
  transition: transform 0.5s ease-in-out;
  position: absolute;
  z-index: -1;
  top: 0;
  left: 0;
  transform-origin: center;
  border-radius: 50%;
`;

const StyleWrapper = styled.div`
  height: max-content;
  width: max-content;
  /* padding: 10px; */
  /* margin-top: 2%; */
  &:hover .buttontext {
    color: ${(props) => (props.$disable ? "black" : "white")};
  }
  &:hover path {
    stroke: "white";
    transition: stroke 0.3s ease-in-out;
  }
  &:hover rect {
    path: "white";
    transition: path 0.3s ease-in-out;
  }
  &:hover .buttonfilleffect {
    transform: ${(props) =>
      props.$disable ? "" : "translate(-50%, -50%) scale(1)"};
  }
`;

const MagneticHeaderButton = forwardRef((props, ref) => {
  // const lightHeader = useSelector((state) => state.CommonStore.lightHeader);
  const [hasMouseEntered, setHasMouseEntered] = useState(false);
  const [pos, setPos] = useState({
    x: 0,
    y: 0,
  });
  function setPosition(e) {
    if (hasMouseEntered) return;
    const x = e.clientX - e.currentTarget.getBoundingClientRect().left - 20;
    const y = e.clientY - e.currentTarget.getBoundingClientRect().top - 20;
    const xButtonRight =
      e.clientX - e.currentTarget.getBoundingClientRect().right + 100;
    const yButtonBottom =
      e.clientY - e.currentTarget.getBoundingClientRect().bottom + 60;
    const newX = -x < xButtonRight ? xButtonRight : -x - 100;
    const newY = -y < yButtonBottom ? yButtonBottom : -y - 60;
    gsap.to(e.currentTarget, {
      x: newX * 0.1,
      y: newY * 0.1,
    });
    setPos({
      x: x,
      y: y,
    });
  }
  function handleMoseLeave() {
    if (props.disable) return;
    const cursor = document.querySelector(".cursor");
    gsap.to(cursor, {
      scale: 0.17,
      opacity: 1,
    });
    const node = ref.current;
    node.querySelector("a").style.animationName = "changeButtonScale";
    node.querySelector("a").style.animationIterationCount = "1";
    gsap.to(".cursor-circle-blast:has(.buttonfilleffect)", {
      x: 0,
      y: 0,
    });
  }
  function handleMouseEnter(e) {
    if (props.disable) return;
    setHasMouseEntered(true);
    setTimeout(() => {
      setHasMouseEntered(false);
    }, 500);
    const x = e.clientX - e.currentTarget.getBoundingClientRect().left - 20;
    const y = e.clientY - e.currentTarget.getBoundingClientRect().top - 20;
    const node = ref.current;
    node.querySelector("a").style.animationName = "transitionButton";
    node.querySelector("a").style.animationIterationCount = "1";
    const cursor = document.querySelector(".cursor");
    gsap.from(cursor, {
      scale: 0.17,
      opacity: 1,
      delay: 0,
    });

    gsap.to(cursor, {
      scale: 0.7,
      opacity: 0,
      delay: 0,
      ease: "power.in",
    });
  }
  function getPath() {
    if (
      props.text === "Let’s Strategize" ||
      props.text === "Discover Solutions"
    ) {
      return "/Contact";
    }
    if (props.text === "Know More") {
      return "/About";
    }
    if (props.text === "Explore") {
      return props.path;
    }
    return "/";
  }
  return (
    <>
      <ButtonForMobileView light={props.light}>
        <Link to={getPath()} onClick={props.onClick}>
          {props.text}
        </Link>
      </ButtonForMobileView>

      <div className="viewOnlyInLaptop">
        <StyleWrapper
          // lightHeader={lightHeader}
          $disable={props.disable}
          onMouseEnter={(e) => {
            e.preventDefault();
            handleMouseEnter(e);
          }}
          onMouseLeave={(e) => {
            e.preventDefault();
            handleMoseLeave();
          }}
          onMouseMove={(e) => setPosition(e)}
          className="cursor-circle-blast"
          ref={ref}
        >
          <Style
            isLightBackground={props.isLightBackground}
            // lightHeader={lightHeader}
            hasMouseEntered={hasMouseEntered}
            isColorFull={props.isColorFull}
            $disable={props.disable}
          >
            <Link
              to={getPath()}
              onClick={props.onClick}
              style={{ marginTop: 0 }}
            >
              <FillAnimation
                isColorFull={props.isColorFull}
                className="buttonfilleffect"
                open={props.open}
                style={{
                  top: pos.y,
                  left: pos.x,
                }}
              />
              {!props.isLoader && (
                <TextContainer
                  isLightBackground={props.isLightBackground}
                  isColorFull={props.isColorFull}
                  className="buttontext"
                >
                  {props.text}
                </TextContainer>
              )}
            </Link>
          </Style>
        </StyleWrapper>
      </div>
    </>
  );
});

export default MagneticHeaderButton;
