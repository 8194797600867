import React, { useRef } from "react";
import {
  BannerText,
  BannerTitle,
  SplineContainer,
} from "../../style/home.style";
import { CommonString } from "../../utils/CommonString";
import { BannerContainer } from "../../style/common.style";
import UseHover from "../../component/UseHover";
import MagneticHeaderButton from "../../component/Button";
import { addLineBreak, onClickScrollTop } from "../../utils/Function";
import Spline from "@splinetool/react-spline";
import { StaffAugmentationBannerContainer } from "../../style/service.style";

export default function BannerSection() {
  const [hoverRef] = UseHover();
  const parent = useRef(0);

  return (
    <StaffAugmentationBannerContainer>
      <SplineContainer>
        <Spline scene="https://prod.spline.design/cX9rZrk94R0gprb0/scene.splinecode" />
      </SplineContainer>
      <BannerContainer moreMargin={true}>
        <BannerTitle>
          {addLineBreak(CommonString.home_banner_title)}
        </BannerTitle>
        <BannerText>{CommonString.home_banner_text}</BannerText>
        <div className="mt-1">
          <MagneticHeaderButton
            isLightBackground={true}
            text={CommonString.discover_solution}
            ref={hoverRef}
            parent={parent}
            onClick={() => onClickScrollTop()}
          />
        </div>
      </BannerContainer>
    </StaffAugmentationBannerContainer>
  );
}
