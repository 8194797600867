import styled from "styled-components";
import { Color } from "../utils/Color";
import { CommonString } from "../utils/CommonString";
import { Grid } from "@mui/material";

const BannerContainer = styled.div`
  background-color: #022435;
  /* width: 100vw; */
  height: 100vh;
  background-position: center;
  display: flex;
  justify-content: center;
  flex-direction: column;
  position: relative;
  div:first {
    max-width: 980px;
  }
  a {
    margin-top: 10%;
  }
  @media (${CommonString.mobile_screen}) {
    background-color: transparent;
    height: auto;
  }
`;
const BannerTitle = styled.h1`
  color: ${Color.light_text};
  font-weight: bold;
  line-height: 2.25rem;
  width: 103vh;
  line-height: 4rem;
  margin: 0;
  margin-top: 10vh;
  @media (${CommonString.mobile_screen}) {
    margin-top: 0;
    width: auto;
    line-height: 2.4rem;
    color: ${Color.primary_color};
  }
`;
const BannerText = styled.p`
  color: ${Color.light_text};
  line-height: 1.7rem;
  letter-spacing: 1.4px;
  width: 90vh;
  margin-top: 1.8rem;
  @media (${CommonString.mobile_screen}) {
    width: auto;
    color: ${Color.secondary_text_color};
  }
`;
const WhoWeAreContainer = styled.div`
  height: auto;
  display: flex;
  flex-direction: column;
  /* justify-content: center; */
  padding: 0vh ${CommonString.common_screen_padding};
  /* padding-top:20vh; */
  position: relative;
  background-color: ${Color.contact_background_color};
  height: 100vh;
  p {
    line-height: 1.8rem;
  }
  .innerContainer {
    margin-top: 12vh;
    position: relative;
    z-index: 1;
  }
  img:first-child {
    height: 55vw;
    position: absolute;
    /* bottom: 0; */
    z-index: 0;
    left: 0;
    @media (${CommonString.mobile_screen}) {
      margin-top: 20px;
      margin-right: ${CommonString.common_screen_padding};
    }
  }
  a img:first-child {
    @media (${CommonString.mobile_screen}) {
      margin-top: 0px;
    }
  }
  @media (${CommonString.mobile_screen}) {
    height: auto;
    .innerContainer {
      margin-top: 0;
    }
    p {
      padding: 0;
    }
    img {
      display: none;
    }
  }
`;
const WhoWeAreTextContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding-right: ${CommonString.common_screen_padding};
  height: 100vh;
  justify-content: flex-start;
  position: relative;
  overflow: auto;
  /* width: 90%; */
  color: ${Color.secondary_color};
  @media (${CommonString.mobile_screen}) {
    height: auto;
    padding-right: 0;
  }
  h3 {
    margin-left: 4vw;
    color: ${Color.primary_color};
    @media (${CommonString.mobile_screen}) {
      margin: 2vh ${CommonString.common_screen_padding};
      margin-left: 0;
    }
  }
  p {
    margin-left: 4vw;
    /* margin-top: 1.5vh; */
    font-size: 1.1rem;
    /* color: ${Color.secondary_text_color}; */
    @media (${CommonString.mobile_screen}) {
      margin: 2vh 0;
    }
  }
  a {
    /* margin-left: 4vw; */
    margin-top: 1vh;
    @media (${CommonString.mobile_screen}) {
      margin: 2vh 0;
    }
  }
`;
const WhoWeAreButton = styled.div`
  margin-left: 4vw;
  margin-top: 4vh;
  @media (${CommonString.mobile_screen}) {
    margin: 2vh 0;
  }
`;
//service
const VideoContainer = styled.div`
  height: 100vh;
  flex: 1;
  overflow: hidden;
  background-color: black;
  transition: 0.65s ease-in-out;
  transition-delay: 0.15s;
  position: relative;
  video {
    width: 100%;
    height: 100%;
    object-fit: cover;
    margin: 0;
    /* transition: 0.9s cubic-bezier(0.77, 0, 0.175, 1);
    transition-delay: 0.2s;
    &:hover {
      width: 80%;
      height: 80%;
      object-fit: cover;
      margin: 10vh;
    } */
  }
  span {
    position: absolute;
    bottom: 8vh;
    color: ${Color.background};
    left: 4vw;
    transition: 0.9s ease-in-out;
    p {
      margin: 2vh 0;
      transition: 1s ease-in-out;
      transition: max-height 0ms.8 ease;
      transition-delay: 0.2s;
      opacity: 0;
      max-height: 0;
      width: 30vw;
    }
    a {
      margin-top: 2vh;
    }
  }
  &:hover {
    flex-grow: 1.4;
    h2 {
      left: 6vw;
    }
    p {
      opacity: 1;
      max-height: 200px;
    }
  }
  img {
    display: none;
  }
  @media (${CommonString.mobile_screen}) {
    height: auto;
    video {
      display: none;
    }
    img {
      display: block;
    }
    span {
      p {
        width: auto;
        max-height: 100%;
        opacity: 1;
      }
    }
  }
  /*  */
`;
const ServiceContainer = styled(Grid)`
  background-color: black;
  height: 100vh;
  position: relative;
  z-index: 1;
  /* video {
    height: 100vh;
    width: 50vw;
    &:hover {
      padding: 0 4vh;
    }
  } */
  img {
    width: 100%;
    height: 100%;
  }
  span {
    position: absolute;
    z-index: 2;
    color: ${Color.background};
    transition: bottom 3s;
    bottom: 0;
    width: 33vw;
    p {
      margin: 5vh ${CommonString.common_screen_padding};
    }
    h2 {
      margin: 5vh ${CommonString.common_screen_padding};
    }
    a {
      margin: 0 ${CommonString.common_screen_padding};
      margin-bottom: 5vh;
    }
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
  @media (${CommonString.mobile_screen}) {
    flex-direction: column;
    overflow: auto;
    height: 100%;
    margin-top: 0;
    img {
      width: 100%;
    }
    a img {
      width: inherit;
    }
    span {
      width: auto;
    }
  }
`;
const ServiceDivContainer = styled(Grid)`
  height: 100vh;
  transition: 2s;
  overflow: hidden;
  transition: padding 0.9s cubic-bezier(0.77, 0, 0.175, 1);
  transition-delay: padding 0.2s;
  &:first-child {
    position: relative;
  }
  &:nth-child(2) {
    position: relative;
  }
  .animatedText {
    height: 0;
    overflow: hidden;
    transition: 0.5s ease-in;
    transition-delay: margin-bottom 0.8s;
    opacity: 0;
  }
  &:hover {
    padding: 4rem;
    .animatedText {
      height: auto;
      overflow: auto;
      margin-bottom: 4rem;
      opacity: 1;
    }
  }
  @media (${CommonString.mobile_screen}) {
    height: auto;
    .animatedText {
      height: auto;
      overflow: hidden;
      opacity: 1;
      margin-bottom: 0;
    }
    &:hover {
      padding: 0;
      .animatedText {
        height: auto;
        overflow: hidden;
        opacity: 1;
        margin-bottom: 0;
      }
    }
  }
`;
//what people say about our work
const WhatPeopleSayContainer = styled.div`
  height: 100vh;
  padding: 10vh ${CommonString.common_screen_padding};
  display: flex;
  align-items: center;
  h2 {
    color: ${Color.secondary_color};
    margin-bottom: 5vh;
  }
  img {
    width: 100%;
  }
  span {
    font-style: italic;
  }
  p {
    margin-top: 1vh;
  }
  @media (${CommonString.mobile_screen}) {
    height: auto;
    img {
      display: none;
    }
  }
`;
const WhatPeopleSayInnerContainer = styled.div`
  padding: ${CommonString.common_screen_padding};
  background-color: ${Color.primary_color};
  color: ${Color.primary_color};
  border-radius: 3.5rem;
  @media (${CommonString.mobile_screen}) {
    border-radius: 0.9375rem;
  }
`;
const BlogContainer = styled.div`
  padding: ${CommonString.common_screen_padding};
  background-color: ${Color.secondary_color};
  border-radius: 1.875rem;
  p {
    line-height: 1.4rem;
  }
  @media (${CommonString.mobile_screen}) {
    border-radius: 0.9375rem;
  }
`;
//Career Section
const CareerSectionContainer = styled.div`
  color: ${Color.background};
  padding: 15vh ${CommonString.common_screen_padding};
  margin: ${(props) =>
    props.margin ? CommonString.common_screen_padding : "0 20vh"}!important;
  border-radius: 1.875rem;
  text-align: center;
  display: flex;
  align-items: center;
  flex-direction: column;
  h2,
  p {
    width: 50vw;
  }
  p {
    margin-bottom: 5vh;
  }
  @media (${CommonString.mobile_screen}) {
    border-radius: 0;
    padding: ${CommonString.common_screen_padding};
    margin: 0 !important;
    height: 80vh;
    display: flex;
    align-items: center;
    justify-content: center;
    h2,
    p {
      width: auto;
    }
  }
`;
//who we support
const WhoWeSupportSectionContainer = styled.div`
  /* height: 100vh; */
  margin: 15vh 0;
  display: flex;
  flex-direction: column;
  /* justify-content: center; */
  padding: 0vh ${CommonString.common_screen_padding};
  /* padding-top:20vh; */
  position: relative;
  background-color: ${Color.contact_background_color};
  p {
    line-height: 1.5rem;
    margin-top: 4vh;
  }
  .innerContainer {
    margin-top: 10vh;
    /* position: relative; */
    z-index: 1;
    /* overflow: auto; */
    img {
      width: 60%;
      margin-bottom: 5vh;
      /* mix-blend-mode: luminosity; */
      filter: grayscale(100%);
      transition: all 0.3s ease-out;
      &:hover {
        filter: grayscale(0);
      }
    }
  }
  @media (${CommonString.mobile_screen}) {
    margin: 5vh 0;
    height: auto;
    flex-direction: column-reverse;
    p {
      line-height: 1.5rem;
      margin-top: 2vh;
    }
    .innerContainer {
      margin-top: 0;
      img {
        width: 70%;
        filter: grayscale(0);
      }
    }
  }
`;
const WhoWeSupportContentContainer = styled.div`
  top: 80;
  color: ${Color.primary_color};
  margin-bottom: 5vh;
  @media (${CommonString.mobile_screen}) {
    /* padding: ${CommonString.common_screen_padding}; */
    /* margin-bottom: 0; */
  }
`;
const ClientImgContainer = styled(Grid)`
  overflow: hidden;
  @media (${CommonString.mobile_screen}) {
    display: flex;
    align-items: center;
    justify-content: center;
  }
`;
//naics code
const NaicsCodeCardContainer = styled.div`
  padding: ${CommonString.common_screen_padding};
  background-color: ${(props) =>
    props.isLight ? Color.secondary_color : Color.primary_color};
  color: ${(props) =>
    props.isLight ? Color.primary_color : Color.secondary_color};
  height: 20vh;
  width: auto;
  position: relative;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  div {
    opacity: 0.15;
    mix-blend-mode: soft-light;
    font-size: 25vh;
    position: absolute;
    top: ${(props) => (props.isLight ? "40px" : "auto")};
    bottom: ${(props) => (props.isLight ? "auto" : "50px")};
    left: 0;
    line-height: 2px;
    font-weight: bold;
  }
  p {
    transform: scale(1);
    transition: all 0.5s ease-out;
  }
  span {
    transform: scale(1);
    transition: all 0.5s ease-out;
  }
  transform: scale(1);
  &:hover p {
    transform: scale(1.1);
  }
  &:hover span {
    transform: scale(1.1);
  }
  @media (${CommonString.mobile_screen}) {
    &:hover p {
      transform: scale(1);
    }
    &:hover span {
      transform: scale(1);
    }
  }
`;
const NaicsCodeContainer = styled(Grid)`
  padding: ${CommonString.common_screen_padding};
  background: ${Color.contact_background_color};
  margin-bottom: 10vh;
  @media (${CommonString.mobile_screen}) {
    padding: 0;
    overflow: hidden;
    margin-bottom: 2vh;
  }
`;
const NaicsCodeTitle = styled.div`
  padding: ${CommonString.common_screen_padding};
  background-color: ${Color.primary_color};
  color: ${Color.background};
  height: 20vh;
  width: auto;
  position: relative;
  display: flex;
  flex-direction: column;
  /* align-items: center; */
  justify-content: center;
  span {
    opacity: 0.15;
    mix-blend-mode: soft-light;
    font-size: 25vh;
    position: absolute;
    bottom: 50px;
    left: 0;
    line-height: 2px;
    font-weight: bold;
  }
`;
const SplineContainer = styled.div`
  position: absolute;
  z-index: 0;
  top: 10vh;
  height: 100vh;
  width: 60vw;
  left: 40vw;
  right: 10vw;
  /* margin: 10vh; */
  /* overflow: hidden; */
  @media (${CommonString.mobile_screen}) {
    position: relative;
    height: 45vh;
    width: 100vw;
    left: 0;
    top: 0;
  }
`;
const BannerTextContainer = styled.div`
  position: absolute;
  z-index: 1;
  top: 0;
  display: flex;
  flex-direction: column;
  height: 100vh;
  justify-content: center;
  h1 {
    margin-top: 0;
  }
  a {
    margin-top: 0;
  }
  @media (${CommonString.mobile_screen}) {
    position: relative;
    height: auto;
    margin-top: 46vh;
  }
`;
const ServiceMainContainer = styled.div`
  display: ${(props) => (props.display ? "flex" : "none")} !important;
  @media (${CommonString.mobile_screen}) {
    display: ${(props) => (!props.display ? "block" : "none")} !important;
  }
`;
export {
  WhoWeAreButton,
  ServiceMainContainer,
  BannerTextContainer,
  SplineContainer,
  VideoContainer,
  ClientImgContainer,
  NaicsCodeContainer,
  WhoWeSupportContentContainer,
  NaicsCodeTitle,
  NaicsCodeCardContainer,
  WhoWeSupportSectionContainer,
  ServiceDivContainer,
  CareerSectionContainer,
  BlogContainer,
  WhatPeopleSayInnerContainer,
  WhatPeopleSayContainer,
  ServiceContainer,
  WhoWeAreTextContainer,
  WhoWeAreContainer,
  BannerContainer,
  BannerTitle,
  BannerText,
};
