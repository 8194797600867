import styled from "styled-components";
import { CommonString } from "../utils/CommonString";
import { Color } from "../utils/Color";

const ContactContainer = styled.div`
  height: 100vh;
  display: flex;
  align-items: center;
  padding: ${CommonString.common_screen_padding} 6vmax;
  color: ${Color.primary_color};
  background-color: ${Color.contact_background_color};
  img {
    width: 100%;
    border-radius: 1.375rem;
  }
  h1 {
    margin: 0;
    font-weight: bold;
  }
  a {
    margin-top: 3vh;
  }
  @media (${CommonString.mobile_screen}) {
    height: auto;
    padding: ${CommonString.common_screen_padding};
    flex-direction: row;
    img {
      display: none;
    }
  }
`;
const ContactMainContainer = styled.div`
  background-color: ${Color.contact_background_color};
  padding-bottom: 10vh;
  @media (${CommonString.mobile_screen}) {
    padding-bottom: 0;
  }
`;
const ContactImg = styled.img`
  display: none;
  @media (${CommonString.mobile_screen}) {
    width: 100%;
    display: block;
  }
`;
export { ContactContainer, ContactMainContainer, ContactImg };
