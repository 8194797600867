import React, { useRef, useState } from "react";
import { CommonString } from "../../utils/CommonString";
import { Grid } from "@mui/material";
import Input from "../../component/Input";
import UseHover from "../../component/UseHover";
import MagneticHeaderButton from "../../component/Button";
import emailjs from "emailjs-com";
// import { onClickScrollTop } from "../../utils/Function";

export default function ContactForm() {
  const [hoverRef] = UseHover();
  const parent = useRef(0);
  const formRef = useRef();
  const [formData, setFormData] = useState({
    first_name: "",
    last_name: "",
    email: "",
    phone_number: "",
    message: "",
  });
  const [errorName, setErrorName] = useState("");
  function handleInputChange(event) {
    const { name, value } = event.target;
    setFormData({ ...formData, [name]: value });
  }
  function onSubmit(e) {
    e.preventDefault();
    if (onValidate()) {
      return;
    }
    const serviceID = "service_60ijtan";
    const templateID = "template_k9xznhn";
    const userID = "Rf5tmdPxJHbH3vzJP";
    // console.log(
    //   "====",
    //   serviceID, // EmailJS service ID
    //   templateID, // EmailJS template ID
    //   formData, // Form element
    //   userID
    // );
    emailjs
      .send(
        serviceID, // EmailJS service ID
        templateID, // EmailJS template ID
        formData, // Form element
        userID
      )
      .then(
        (result) => {
          console.log("Email successfully sent!", result.text);
        },
        (error) => {
          console.error("Failed to send the email.", error.text);
        }
      );
  }
  function onValidate() {
    if (formData.first_name === "") {
      setErrorName(CommonString.first_name);
      return;
    }
    if (formData.last_name === "") {
      setErrorName(CommonString.last_name);
      return;
    }
    if (formData.email === "") {
      setErrorName(CommonString.email);
      return;
    }
    if (formData.email !== "") {
      function validateEmail(email) {
        const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        return regex.test(email);
      }
      if (!validateEmail(formData.email)) {
        setErrorName(CommonString.email);
        return;
      }
    }
  }

  return (
    <div>
      <h2>
        Contact <span>Us</span>
      </h2>
      <p>{CommonString.lets_make_our_strategy}</p>
      <form ref={formRef} onSubmit={(e) => onSubmit(e)}>
        <Grid container spacing={2}>
          <Grid item md={6} xs={12}>
            <Input
              name={"first_name"}
              placeholder={CommonString.first_name}
              value={formData.first_name}
              onChange={handleInputChange}
              required={errorName === CommonString.first_name}
              onBlur={() => setErrorName("")}
            />
          </Grid>
          <Grid item md={6} xs={12}>
            <Input
              name={"last_name"}
              placeholder={CommonString.last_name}
              value={formData.last_name}
              onChange={handleInputChange}
              required={errorName === CommonString.last_name}
              onBlur={() => setErrorName("")}
            />
          </Grid>
          <Grid item md={6} xs={12}>
            <Input
              name={"email"}
              placeholder={CommonString.email}
              value={formData.email}
              onChange={handleInputChange}
              required={errorName === CommonString.email}
              onBlur={() => setErrorName("")}
            />
          </Grid>
          <Grid item md={6} xs={12}>
            <Input
              type="number"
              name={"phone_number"}
              placeholder={CommonString.phone_number_placeholder}
              value={formData.phone_number}
              onChange={handleInputChange}
            />
          </Grid>
        </Grid>
        <Input
          multiline
          placeholder={CommonString.message}
          name={"message"}
          value={formData.message}
          onChange={handleInputChange}
        />
        <MagneticHeaderButton
          // onClick={() => onClickScrollTop()}
          isLightBackground={true}
          text={CommonString.submit}
          ref={hoverRef}
          parent={parent}
          onClick={onSubmit}
        />
      </form>
    </div>
  );
}
