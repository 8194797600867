export var CommonString = {
  laptop_screen: "(min-width: 1025px) and (max-width: 1280px)",
  tablet_screen: "(min-width: 768px) and (max-width: 1024px)",
  mobile_screen: "max-width: 1024px",
  large_screen: "(min-width: 1281px)",
  // screen padding
  common_screen_padding: window.innerWidth >= 1500 ? "4vmax" : "4vmax",
  // menu name
  home: "Home",
  about: "About",
  blog: "Blog",
  services: "Services",
  careers: "Careers",
  staff_augmentation: "Staff Augmentation",
  //footer
  contact_with_us: "Connect with us",
  e7_strategies: "E7Strategies LLC",
  company_location: "1620 Q ST NW, Washington, DC",
  company_pin_code: "20009",
  termsAndCondition: "Terms and Conditions",
  cookie_policy: "Cookie Policy",
  privacy_policy: "Privacy Policy",
  // button name
  lets_strategize: "Let’s Strategize",
  discover_solution: "Discover Solutions",
  know_more: "Know More",
  contact: "Contact",
  explore: "Explore",
  submit: "Submit",
  //contact information
  email_id: "info@strategies.com",
  phone_number: "1-202-957-5358",
  linkedin: "Linkedin",
  //home page text
  home_banner_title: `Steering visions \n with innovation`,
  home_banner_text:
    "We craft innovative solutions that drive growth, streamline operations, and empower businesses to thrive in dynamic and evolving markets.",
  who_we_are_title: "Who We Are",
  who_we_are_text_1:
    "E7Strategies is a certified woman-owned business delivering tailored IT Consulting and Staff Augmentation services to government and commercial clients nationwide.",
  who_we_are_text_2:
    "At E7Strategies, we specialize in developing comprehensive and innovative strategies that transform business visions into reality. By aligning cutting-edge technology with strategic insights, we help organizations bring their ideas to life, ensuring sustainable growth and long-term success. Our tailored solutions empower businesses to adapt to dynamic markets, streamline operations, and unlock new opportunities, driving them toward their full potential.",
  it_consulting: "IT Consulting",
  it_consulting_text:
    "We drive growth and innovation with tailored solutions that meet your unique needs. Achieve success through cutting-edge technology and expert strategies.",
  // staff_augmentation: "IT Consulting",
  staff_augmentation_text:
    "Boost your productivity and scale your workforce efficiently while maintaining full control. Dynamic solutions to strategically integrate skilled professionals into your team.",
  health_care_it: "Health Care IT",
  stay_in_touch: "Stay in touch",
  certifications: "Certifications",
  //What people say about our work
  what_people_say_about_our_work: "What people say about our work",
  blog_1: ` “E7STRATEGIES transformed the way we approach data-driven
                  decision-making. Their customized dashboards and
                  visualizations allow us to monitor key metrics effortlessly.
                  Plus, their commitment to accuracy and data integrity is
                  commendable.”`,
  blog_1_client_name: "- CEO, ABC Corporation",
  // who we support
  who_we: `Who We`,
  support: "Support",
  who_we_support_text_1:
    "We proudly support government agencies and commercial clients, delivering effective solutions to meet their unique needs.",
  who_we_support_text_2:
    "They cater to sectors like government, banking, healthcare IT, and engineering, offering customized solutions",
  //career section
  careersTitle: "Careers Title",
  careerText: `“E7STRATEGIES transformed the way we approach data-driven decision-making. Their customized dashboards and visualizations allow us to monitor key metrics effortlessly. Plus, their commitment to accuracy and data integrity is commendable.”`,
  //about banner section
  about_us: "About Us",
  about_banner_title: "Navigating Vision to Reality",
  about_banner_text:
    "We craft innovative solutions that drive growth, streamline operations, and empower businesses to thrive in dynamic and evolving markets.",
  //about Our Story section
  our_story: "Our Story",
  our_story_text_1:
    "E7Strategies was founded with a clear mission to help businesses bring their visionary ideas to life. Turning ambitious goals into reality requires more than just innovative ideas—it requires technical expertise and strategies to sustain them. We are the bridge between innovation and execution.",
  our_story_text_2:
    "We believe that every organization, no matter its size or industry, should have access to top-tier technical solutions and expert guidance to succeed in today’s rapidly evolving market. e work side by side with you, offering the guidance and expertise necessary to transform your visionary ideas into practical, actionable results. With a deep commitment to integrity, efficiency, and innovation, we help businesses navigate the complexities of modern technology, ensuring that their visions not only take shape but thrive in the real world.",
  our_story_text_3: "",
  our_story_text_4: "",
  //core value section
  core_values: "Core Values",
  core_value_1_title: "quality work",
  core_value_1_text: "CONTINUED IMPROVEMENT",
  //contact screen
  contact_us: "Contact Us",
  lets_make_our_strategy: "Let’s make our strategy.",
  first_name: "First Name*",
  last_name: "Last Name*",
  email: "Email address*",
  phone_number_placeholder: "Phone number",
  message: "Message*",
  //service screen
  service_1_title: "Staff Augmentation",
  service_1_text:
    "Explore the cutting-edge advancements in healthcare IT, from electronic health records and telemedicine to AI-driven diagnostics and cybersecurity. This field is revolutionizing patient care, improving efficiency, and driving new possibilities for the future of medicine",
  service_2_title: "IT Consulting",
  service_2_text:
    "Enhance your business with tailored IT solutions and expert guidance. Optimize technology, boost security, and drive efficiency with our comprehensive consulting services!",
  we_provide: "we provide",
  IT_sub_service_1: "Database/storage management",
  IT_sub_service_1_text:
    "Design, implement, and manage scalable databases and storage solutions to ensure optimal performance, data integrity, and security for your critical business information ",
  IT_sub_service_2: "Big Data & Analytics Services",
  IT_sub_service_2_text:
    "Harness the power of big data to provide actionable insights, enabling data-driven decision-making and the optimization of business strategies. ",
  IT_sub_service_3: "Cloud Architecture ",
  IT_sub_service_3_text:
    "Develop and deploy customized cloud infrastructure solutions that enhance flexibility, scalability, and cost-efficiency, while ensuring smooth cloud migration and management. ",
  IT_sub_service_4: "IT Architecting Solutions ",
  IT_sub_service_4_text:
    "Design and build robust IT architectures that align with your business goals, ensuring efficient system integration, scalability, and long-term technological growth. ",
  IT_sub_service_5: "Cyber Security ",
  IT_sub_service_5_text:
    "Comprehensive cybersecurity solutions protect digital assets from threats through proactive monitoring, risk assessment, and advanced defense mechanisms. ",
  IT_sub_service_6: "Project Management ",
  IT_sub_service_6_text:
    "Project management services to ensure timely delivery, resource optimization, and successful execution of your projects, aligning with business objectives. ",
  IT_sub_service_7: "Business strategy and planning support ",
  IT_sub_service_7_text:
    "Strategic planning and consulting services, helping businesses align their IT infrastructure with long-term goals and fostering sustainable growth. ",
  IT_sub_service_8: "Training in IT applications ",
  IT_sub_service_8_text:
    "Hands-on training and knowledge transfer for IT applications, empowering your teams to maximize productivity and efficiency with the latest technology tools. ",
  IT_sub_service_9: "IT Hardware and Software ",
  IT_sub_service_9_text:
    "Supply, configure, and manage a wide range of IT hardware and software solutions, ensuring seamless operations and enhanced productivity for your business. ",
  IT_sub_service_10: "Medical Data Analysis ",
  IT_sub_service_10_text:
    "Specialized data analysis services for medical research, helping organizations derive valuable insights from complex healthcare data for informed decision-making. ",
  IT_sub_service_11: "EEG/MRI Research lab IT solutions ",
  IT_sub_service_11_text:
    "Customized IT solutions for EEG/MRI research labs, ensuring seamless data collection, analysis, and secure storage of neurological research data. ",
  IT_sub_service_12: "Clinical database development ",
  IT_sub_service_12_text:
    "Develop and manage clinical databases that streamline patient data collection, storage, and analysis, supporting healthcare providers in delivering better patient outcomes. ",
  //staff augmentation sub service
  staff_augmentation_sub_service_1: "Contingency Workforce Staffing",
  staff_augmentation_sub_service_1_text:
    "On-demand, flexible workforce solutions, enabling businesses to quickly scale their teams for temporary or project-based needs while maintaining operational efficiency.",
  staff_augmentation_sub_service_2: "Contractual Staffing",
  staff_augmentation_sub_service_2_text:
    "Short-term employees on a fixed contract basis, allowing businesses to fill roles for specific time frames or projects without long-term commitments.",
  staff_augmentation_sub_service_3: "Contract-to-Permanent Staffing ",
  staff_augmentation_sub_service_3_text:
    "Contract-to-permanent staffing, giving businesses the opportunity to evaluate a candidate's performance on a temporary basis before transitioning them into a full-time role.",
  staff_augmentation_sub_service_4: "Permanent Staffing",
  staff_augmentation_sub_service_4_text:
    "Permanent staffing solution connecting businesses with top talent for full-time positions, ensuring a strong match between the candidate's skills and the company’s long-term goals.",
  naics: "NAICS",
  codes: "Codes",
  our_mission: "Our Mission",
  our_mission_text:
    "Navigate the realization of visionary ideas for organizations using technical expertise and sustainable strategies and drive towards mutual success.",
  our_vision: "Our Vision",
  our_vision_text:
    "We’re here to help! Whether you have questions about our services, need support, or want to discuss how we can assist you in achieving your goals, we’d love to hear from you.",
  // IT Services Process
  process: "Process",
  it_services_process_1: "Assessment & Discovery",
  it_services_process_1_text:
    "We begin by understanding the client's business needs, current infrastructure, challenges, and goals. And perform a thorough gap analysis to identify areas of improvement through data gathering, interviews, and technical assessments. ",
  it_services_process_2: "Planning & Strategy",
  it_services_process_2_text:
    "Based on the assessment, we develop a customized strategy that aligns with business objectives, clearly defining the project scope, timeline, deliverables, and key performance indicators (KPIs) while considering risks and resource requirements. ",
  it_services_process_3: "Implementation & Execution",
  it_services_process_3_text:
    "Our team executes the planned solutions, such as software deployment, system integration, or infrastructure upgrades, while ensuring project management & budget adherence, and providing training for smooth user adoption. ",
  it_services_process_4: "Monitoring & Optimization",
  it_services_process_4_text:
    "Post-implementation, we continuously monitor system performance, troubleshoot issues, collect feedback, and provide ongoing support and optimization to ensure the solution evolves with the client’s needs. ",
  sa_services_process_1: "Requirement Analysis",
  sa_services_process_1_text:
    "We work with the client to assess their staffing needs, defining clear roles and required qualifications by analyzing the current team's capacity and identifying gaps to be filled. ",
  sa_services_process_2: "Talent Sourcing",
  sa_services_process_2_text:
    "We source candidates from multiple channels, conduct thorough screening, interviews, and background checks, and present the most qualified candidates to the client for final selection. ",
  sa_services_process_3: "Talent Onboarding",
  sa_services_process_3_text:
    "Our team manages the administrative onboarding process, facilitates integration into the client's team, and ensures the augmented staff are equipped with the necessary training and resources. ",
  sa_services_process_4: "Performance Management",
  sa_services_process_4_text:
    "We continuously monitor staff performance, provide ongoing support, and conduct periodic reviews to ensure they meet client expectations, adjusting as necessary based on project progress. ",
  contract_vehicle: "Contract Vehicles",
};
