import styled from "styled-components";
import { Color } from "../utils/Color";
import { CommonString } from "../utils/CommonString";

const FooterContainer = styled.div`
  background-color: ${Color.primary_color};
  height: 100vh;
  position: relative;
  /* margin-top: 10vh; */
  /* padding: 0 ${CommonString.common_screen_padding}; */
  padding-bottom: 0;
  display: flex;
  align-items: center;
  /* img:nth-child(2) {
    position: absolute;
    bottom: 1vh;
    right: 1vw;
    width: 100%;
  } */
  ul {
    list-style: none;
    padding: 0;
  }
  @media (${CommonString.mobile_screen}) {
    height: auto;
    padding: 3vmax;
    img:nth-child(1) {
      width: 100%;
    }
  }
`;
const FooterImg3 = styled.img`
  background-size: cover;
  /* width: auto !important; */
  border-radius: 20px;
  position: relative;
  margin-top: 2vh;
  height: 65.25vh;
  width: 100%;
  /* height: 90%; */
  /* margin-left: 5vh; */
  /* width: -webkit-fill-available; */
  /* margin-top: 2vh; */
  @media (${CommonString.mobile_screen}) {
    display: none;
    margin-top: 0;
  }
`;
const ContactInfoContainer = styled.div`
  background-color: ${Color.footer_container_color};
  color: ${Color.primary_color};
  border-radius: 18px;
  padding: 30px;
  /* z-index: 1; */
  position: relative;
  width: -webkit-fill-available;
  /* margin-top: 2vh; */
  /* margin: 0 8vh; */
  p {
    display: flex;
    align-items: center;
  }
  h4 {
    margin-block-start: 0;
    margin-block-end: 0;
    margin-bottom: 25px;
  }
  img {
    margin: 1.5vh 2vh;
    margin-left: 0;
    width: 1rem !important;
    /* margin: 2vh; */
  }
  @media (${CommonString.mobile_screen}) {
    padding: 3vmax;
    margin-top: 0;
    margin: 2vh 0;
    p {
      font-weight: bold;
    }
    img {
      margin: 1vh 2vh;
      margin-left: 0;
      width: 1.4rem !important;
      /* margin: 2vh; */
    }
    /* margin-top: 0; */
  }
`;
const VectorLogo = styled.div`
  svg {
    height: 80vh !important;
    position: absolute;
    left: 43vw !important;
    right: 0 !important;
    bottom: 0 !important;
    z-index: 0;
    fill: ${Color.background};
    transition: opacity 0.7s;
    /* transition-delay: 0.1s; */
    opacity: ${(props) => (props.isActive ? 0.4 : 1)};
    @media (${CommonString.mobile_screen}) {
      display: none;
    }
    /* @media (${CommonString.tablet_screen}) {
      height: 80vw !important;
      left: 7vw !important;
    } */
  }
`;
const LocationContainer = styled.div`
  position: absolute;
  top: 3vmax;
  left: 2vw;
  color: ${Color.primary_color};
  p {
    font-weight: bold;
  }
  img {
    width: 10vh !important;
  }
  h1 {
    width: 20vw;
    margin-top: 17vh;
    font-size: 4rem;
    font-weight: bold;
  }
  @media (${CommonString.mobile_screen}) {
    padding: 0 3vmax;
    top: 3vmax;
    /* display: flex; */
    align-items: center;
    h1 {
      width: 50vw;
      margin: 0;
      margin-top: 4vh;
      font-size: 3rem;
    }
  }
`;
const FooterFlexContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 100vh;
  @media (${CommonString.mobile_screen}) {
    flex-direction: column;
  }
`;
const MiddleContentContainer = styled.div`
  width: 40%;
  /* margin-top: 2vh; */
  /* height: 73vh; */
  /* margin: 0 10vh; */
  height: 70vh;
  margin-left: 4vw;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  div:first-child {
    margin-bottom: 5vh;
  }
  @media (${CommonString.mobile_screen}) {
    height: auto;
    margin-top: 0;
    div:first-child {
      /* margin-top: -2vh; */
      margin-bottom: 0;
    }
  }
`;
const FooterFirstContainer = styled.div`
  position: relative;
  /* padding-right: 5vh; */
  margin-top: 2vh;
  /* margin:2vh; */
  /* background-color: red; */
  margin-right: 3vw;
  height: 100vh;
  width: 40vw;
  svg {
    width: 100%;
    height: 100%;
  }
  @media (${CommonString.mobile_screen}) {
    padding-right: 0;
    margin-bottom: -0.7vh;
    /* margin-top: 1vh; */
  }
`;
const FooterBottomContainer = styled.div`
  display: flex;
  justify-content: center;
  color: ${Color.button_secondary};
  text-decoration: underline;
  font-size: 0.9rem;
  position: absolute;
  z-index: 1;
  bottom: 2vh;
  margin-top: 5vh;
  left: 0;
  right: 0;
  white-space: nowrap;
  transition: 1s;
  /* opacity: 0; */
  transform: translateY(10px);
  transition-delay: 0.2s;
  a {
    cursor: pointer;
    margin: 10px 20px;
  }
  @media (${CommonString.mobile_screen}) {
    /* margin-top: 1vh; */
  }
`;
const CertificateContainer = styled.div`
  display: flex;
  align-items: center;
  img {
    mix-blend-mode: luminosity;
    width: 100% !important;
  }
  img:nth-child(2) {
    margin: 0 10px;
  }
`;
const LocationBox = styled.div`
  display: flex;
  align-items: center;
  margin: 0 !important;
  margin-top: 10px;
  img {
    mix-blend-mode: luminosity;
    width: 5vh !important;
    margin-right: 1vw;
  }
  @media (${CommonString.mobile_screen}) {
    /* margin-top: 1vh; */
    img {
      width: 1.4rem !important;
      margin: 2vh;
      margin-left: 0;
    }
  }
`;

const FooterContentContainer = styled.div`
  /* transition: opacity 1s;
  opacity: 0;
  transform: translateY(10px);
  transition-delay: 0.2s; */
  /* opacity: ${(props) => (props.isActive ? 1 : 0)}; */
`;
const LeftSideContainer = styled.div`
  width: 40%;
  height: 100%;
  position: relative;
  span {
    background-color: red;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    position: relative;
  }
  p {
    margin: 0 30px;
    font-size: 5vmax;
    font-weight: bold;
    color: ${Color.primary_color};
  }
`;
const LeftSideIconContainer = styled.div`
  position: absolute;
  width: 80px;
  height: 80px;
  border-radius: 20px;
  background-color: red;
  bottom: 0;
  right: 0;
  z-index: 10;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${Color.secondary_color};
  svg {
    width: 35px;
    height: 35px;
    color: ${Color.primary_color};
  }
`;
const FooterMainContainer = styled.div`
  height: auto;
  background-color: #041218;
  color: #f9fafb;
  p {
    line-height: 1.5rem;
    margin-bottom: 1vh;
    font-size: 14px;
  }
  span {
    font-size: 14px;
  }
  a {
    padding-bottom: 5px;
    width: fit-content;
    &:hover {
      transition: 0.4s ease-out;
      /* font-size: 17px; */
      /* border-bottom: 1px solid ${Color.background}; */
    }
  }
  @media (${CommonString.mobile_screen}) {
    height: auto !important;
  }
`;
const FooterInnerFirstContainer = styled.div`
  background-color: #2d323e;
  padding: 30px;
  margin: 4vmax;
  border-radius: 20px;
  color: #f9fafb;
  display: flex;
  justify-content: space-between;
  overflow: hidden;
  svg {
    margin-left: 1vw;
    width: 20px;
  }
  h4 {
    margin: 0;
    margin-bottom: 10px;
  }
  span {
    padding: 10vh 20vh;
    background-color: #383e4d;
    border-radius: 50%;
    margin: -5.5vmax;
    overflow: hidden;
    display: flex;
    align-items: center;
  }
  a {
    display: flex;
    align-items: center;
    svg {
      margin-left: 10px;
    }
  }
  @media (${CommonString.mobile_screen}) {
    flex-direction: column;
    span {
      flex-direction: column;
      margin: 0;
      padding: 10px;
      border-radius: 20px;
      margin-top: 20px;
      a {
        padding-bottom: 0;
      }
    }
  }
  /* filter: blur(8px); */
`;
const Title = styled.div`
  color: ${Color.background};
  font-style: normal;
  font-weight: 500;
  line-height: 0.875rem;
  letter-spacing: 0.025rem;
  /* text-transform: uppercase; */
  font-size: 16px;
  margin-bottom: 4vh;
  @media (${CommonString.mobile_screen}) {
    margin-top: 4vh;
  }
`;
const ContactInfoWithLogo = styled.div`
  display: flex;
  align-items: flex-end;
  flex-direction: column;
  img {
    width: 60%;
    height: 100%;
    padding-bottom: 3vh;
    filter: grayscale(100%);
    /* border-bottom: 1px solid #f9fafb; */
    /* margin-bottom: 4vh; */
  }
  a {
    font-size: 16px;
    &:hover {
      border: none;
    }
  }
  @media (${CommonString.mobile_screen}) {
    /* flex-direction: row; */
    align-items: center;
    margin: 20px 0;
    img {
      padding-bottom: 0;
      border-bottom: none;
      padding-bottom: 4vh;
      margin-bottom: 0;
    }
    a {
      margin-left: 6vw;
    }
  }
`;
const BottomContainer = styled.div`
  padding: 0 4vmax;
  display: flex;
  background-color: #041218;
  justify-content: space-between;
  align-items: end;
  margin-bottom: 0.5vmax;
  p {
    font-size: 12px;
    margin-bottom: 0;
  }
  a {
    padding-bottom: 0 !important;
  }
  span {
    display: flex;
    align-items: end;
    color: #e4e4e4;
    /* align-items: center; */
    p {
      margin: 0 15px;
      cursor: pointer;
      margin-bottom: 0;
    }
  }
  div {
    border: 1px solid #f9fafb;
    border-radius: 50px;
    width: 50px;
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    margin-left: 15px;
  }
  @media (${CommonString.mobile_screen}) {
    flex-direction: column-reverse;
    align-items: center;
    div {
      display: none;
    }
  }
`;
const CertificateImg = styled.img`
  /* width: 150px; */
  scale: 0.9;
  filter: grayscale(100%);
  @media (${CommonString.mobile_screen}) {
    width: 100%;
  }
`;
const MiddleContainer = styled.div`
  display: flex;
  padding: 0 4vmax;
  background-color: #041218;
  p {
    white-space: nowrap;
  }
  @media (${CommonString.mobile_screen}) {
    flex-direction: column;
    div {
      width: 100% !important;
    }
    /* p {
      flex-wrap: nowrap;
    } */
  }
`;
const CertificateImgContainer = styled.div`
  display: ${(props) => (props.displayInMobileView ? "none" : "flex")};
  padding: 0 4vmax;
  justify-content: space-around;

  @media (${CommonString.mobile_screen}) {
    flex-direction: column;
    display: ${(props) => (props.displayInMobileView ? "flex" : "none")};
    padding: 0;
    div {
      width: 100% !important;
    }
    /* p {
      flex-wrap: nowrap;
    } */
  }
`;
export {
  CertificateImgContainer,
  MiddleContainer,
  CertificateImg,
  BottomContainer,
  ContactInfoWithLogo,
  Title,
  FooterInnerFirstContainer,
  FooterMainContainer,
  LeftSideIconContainer,
  LeftSideContainer,
  FooterContentContainer,
  LocationBox,
  CertificateContainer,
  FooterBottomContainer,
  FooterFirstContainer,
  MiddleContentContainer,
  FooterFlexContainer,
  FooterContainer,
  FooterImg3,
  ContactInfoContainer,
  VectorLogo,
  LocationContainer,
};
