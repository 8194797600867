import "./App.css";
import { useDispatch, useSelector } from "react-redux";
import Footer from "./component/Footer";
import Header from "./component/Header";
import { ReactLenis } from "@studio-freight/react-lenis";
import {
  onChangeIsOpenServiceDropDown,
  onChangeLightHeader,
} from "./store/CommonStore";
import { useEffect, useState } from "react";
import Loader from "./component/Loader";
import AppRoutes from "./Routes";
import ScrollToTop from "./component/ScrollToTop";

function App() {
  const dispatch = useDispatch();
  const [scrollIndex, setScrollIndex] = useState(0);
  const lightHeader = useSelector((state) => state.CommonStore.lightHeader);
  const is_open_mobile_nav_bar = useSelector(
    (state) => state.CommonStore.is_open_mobile_nav_bar
  );

  useEffect(() => {
    window.scrollTo(0, 0);
    onLoad();
  }, []);
  useEffect(() => {
    const handleScroll = () => {
      const currentScroll = window.scrollY;
      const windowHeight = window.innerHeight;
      const newIndex = Math.floor(currentScroll / windowHeight);
      if (newIndex !== scrollIndex) {
        setScrollIndex(newIndex);
      }
    };
    if (scrollIndex === 0) {
      dispatch(onChangeLightHeader(true));
    } else {
      dispatch(onChangeLightHeader(false));
    }
    window.addEventListener("scroll", handleScroll);
  }, [scrollIndex]);

  function onLoad() {
    var data = document.getElementById("root");
    if (data !== undefined) {
      const preloader = document.getElementById("preloader");
      if (preloader) {
        preloader.style.display = "none";
      }
    }
  }
  return (
    <ReactLenis root>
      <Loader />
      <ScrollToTop />
      <div
        style={{
          height: is_open_mobile_nav_bar ? "100vh" : "inherit",
          overflow: is_open_mobile_nav_bar ? "hidden" : "inherit",
        }}
        onClick={() => dispatch(onChangeIsOpenServiceDropDown(false))}
      >
        <Header lightHeader={lightHeader} />
        <AppRoutes />
        <Footer />
      </div>
    </ReactLenis>
  );
}

export default App;
