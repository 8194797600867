import styled from "styled-components";
import { CommonString } from "../utils/CommonString";
import { Color } from "../utils/Color";

const Container = styled.div`
  margin: ${CommonString.common_screen_padding};
`;
const InputContainer = styled.div`
  margin: 1vh;
  input {
    padding: 15px 0;
    border: none;
    margin-top: 15px;
    border-bottom: 1px solid ${Color.secondary_text_color};
    width: 100%;
    color: ${Color.background};
    background-color: transparent !important;
    font-size: 16px;
    &:focus-visible {
      outline: none;
      border-bottom: 1px solid ${Color.background} !important;
    }
    &::placeholder {
      font-weight: 500;
      letter-spacing: 0.3px;
      color: ${Color.secondary_text_color};
    }
    &::-ms-user-select {
      background-color: transparent !important;
    }
  }
  textarea {
    font-size: 16px;
    margin-top: 15px;
    padding: 15px 0;
    border: none;
    border-bottom: 1px solid ${Color.secondary_text_color};
    background-color: transparent;
    width: 100%;
    height: 10vh;
    max-height: 10vh;
    color: ${Color.background};
    max-width: 100%;
    &:focus-visible {
      outline: none;
      border-bottom: 1px solid ${Color.background}!important;
    }
    &::placeholder {
      font-weight: 500;
      letter-spacing: 0.3px;
      color: ${Color.secondary_text_color};
      font-family: "Red Hat Display", sans-serif;
    }
  }
  @media (${CommonString.mobile_screen}) {
    textarea {
      color: ${Color.primary_color};
    }
    input {
      color: ${Color.primary_color};
    }
  }
`;
const BannerImg = styled.img`
  /* display: none; */
  object-fit: cover;
  height: 100vh;
  width: 100vw;
  /* position: absolute;
  z-index: 0; */
  @media (${CommonString.mobile_screen}) {
    width: 100%;
    height: 50vh;
    display: block;
  }
`;
const BannerContainer = styled.div`
  position: absolute;
  z-index: 1;
  margin: ${CommonString.common_screen_padding};
  color: ${Color.light_text} !important;
  @media (${CommonString.mobile_screen}) {
    position: relative;
    color: ${Color.primary_color} !important;
    p {
      color: ${Color.primary_color} !important;
      overflow: hidden !important;
    }
    h1 {
      color: ${Color.background} !important;
      margin-top: ${(props) => (props.moreMargin ? "-16vh" : "-12vh")};
    }
    p {
      margin-top: 7vh;
    }
    /* width: 100%; */
    display: block;
  }
`;
const ButtonForMobileView = styled.a`
  display: none !important;
  @media (${CommonString.mobile_screen}) {
    display: block;
    background-color: ${(props) =>
      props.light ? Color.background : Color.primary_color};
    color: ${(props) => (props.light ? Color.primary_color : Color.background)};
    border-radius: 50px;
    padding: 10px 30px;
    line-height: 1.25;
    letter-spacing: 0.025em;
    width: fit-content;
    margin-top: 3vh;
  }
`;
export {
  ButtonForMobileView,
  Container,
  InputContainer,
  BannerImg,
  BannerContainer,
};
