import React, { useEffect } from "react";
import BannerSection from "./BannerSection";
import OurStorySection from "./OurStorySection";
import WhoWeSupportSection from "../home/WhoWeSupportSection";
import MissionVisionSection from "./MissionVisionSection";
import Certifications from "./Certifications";
import Loader from "../../component/Loader";
import { useDispatch } from "react-redux";
import { onChangeLightHeader } from "../../store/CommonStore";

export default function AboutScreen() {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(onChangeLightHeader(true));
  }, []);

  return (
    <>
      <Loader />
      <BannerSection />
      <OurStorySection />
      <MissionVisionSection />
      <WhoWeSupportSection />
      <Certifications />
    </>
  );
}
