import React, { useRef } from "react";
import { ServiceMainContainer, VideoContainer } from "../../style/home.style";
import { CommonString } from "../../utils/CommonString";
import MagneticHeaderButton from "../../component/Button";
import UseHover from "../../component/UseHover";
import { useDispatch } from "react-redux";
import { onChangeActiveMenuStatus } from "../../store/CommonStore";
import video_1 from "../../assets/Video/video_1.mp4";
import video_2 from "../../assets/Video/video_2.mp4";
import service_img_1_mobile from "../../assets/images/service_img_1_mobile.svg";
import service_img_2_mobile from "../../assets/images/service_img_2_mobile.svg";

export default function ServiceSection() {
  const dispatch = useDispatch();
  const [hoverRef] = UseHover();
  const [hoverRef1] = UseHover();
  const parent = useRef(0);
  const parent1 = useRef(0);

  const handleClick = (event) => {
    event.target.parentNode.remove();
    window.scrollTo(0, 0);
    dispatch(onChangeActiveMenuStatus(event));
    localStorage.setItem("activeMenu", event);
  };
  function onHoverVideo(id) {
    const video = document.getElementById(id);
    if (video.pause) {
      video.play();
    }
  }
  function onMouseOutVideo(id) {
    const video = document.getElementById(id);
    if (video.play) {
      video.pause();
      video.currentTime = 2;
    }
  }

  return (
    <>
      <ServiceMainContainer display={false}>
        <VideoContainer>
          <img src={service_img_1_mobile} alt="" />
          <video loop={true} muted src={`${video_2}#t=1`} autoPlay></video>
          <span>
            <h2>{CommonString.it_consulting}</h2>
            <p>{CommonString.it_consulting_text}</p>
            <MagneticHeaderButton
              light={true}
              isLightBackground={true}
              text={CommonString.explore}
              ref={hoverRef}
              parent={parent}
              onClick={() => handleClick(CommonString.it_consulting)}
            />
          </span>
        </VideoContainer>
        <VideoContainer>
          <img src={service_img_2_mobile} alt="" />
          <video loop={true} muted autoPlay src={`${video_1}#t=1`}></video>
          <span>
            <h2>{CommonString.staff_augmentation}</h2>
            <p>{CommonString.staff_augmentation_text}</p>
            <MagneticHeaderButton
              light={true}
              isLightBackground={true}
              text={CommonString.explore}
              ref={hoverRef}
              parent={parent}
              onClick={() => handleClick(CommonString.staff_augmentation)}
            />
          </span>
        </VideoContainer>
      </ServiceMainContainer>
      <ServiceMainContainer display={true}>
        <VideoContainer
          onMouseEnter={() => onHoverVideo("myVideo")}
          onMouseLeave={() => onMouseOutVideo("myVideo")}
        >
          <video
            id="myVideo"
            loop={true}
            muted
            src={`${video_2}#t=1`}
            allow="autoplay"
          ></video>
          <span>
            <h2>{CommonString.it_consulting}</h2>
            <p>{CommonString.it_consulting_text}</p>
            <MagneticHeaderButton
              light={true}
              isLightBackground={true}
              path="/ITConsulting"
              text={CommonString.explore}
              ref={hoverRef1}
              parent={parent1}
              onClick={() => handleClick(CommonString.it_consulting)}
            />
          </span>
        </VideoContainer>
        <VideoContainer
          onMouseEnter={() => onHoverVideo("myVideo1")}
          onMouseLeave={() => onMouseOutVideo("myVideo1")}
        >
          <video
            id="myVideo1"
            loop={true}
            muted
            allow="autoplay"
            src={`${video_1}#t=1`}
          ></video>
          <span>
            <h2>{CommonString.staff_augmentation}</h2>
            <p>{CommonString.staff_augmentation_text}</p>
            <MagneticHeaderButton
              light={true}
              isLightBackground={true}
              text={CommonString.explore}
              ref={hoverRef}
              path="/StaffAugmentation"
              parent={parent}
              onClick={() => handleClick(CommonString.staff_augmentation)}
            />
          </span>
        </VideoContainer>
      </ServiceMainContainer>
    </>
  );
}
