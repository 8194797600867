import React from "react";
import {
  CoreValueContainer,
  CoreValueFlexContainer,
  CoreValueInnerContainer,
  CoreValueText,
  CoreValueTitleForMobileView,
} from "../../../style/about.style";
import { Grid } from "@mui/material";
import { Color } from "../../../utils/Color";
import { CommonString } from "../../../utils/CommonString";
import img_1 from "../../../assets/images/01.svg";
import img_2 from "../../../assets/images/02.svg";
import img_3 from "../../../assets/images/03.svg";
import img_4 from "../../../assets/images/04.svg";

export default function StaffAugmentationProcess() {
  return (
    <CoreValueContainer>
      <Grid container>
        <Grid item md={1} xs={12}>
          <CoreValueInnerContainer
            mobileView={true}
            margin={true}
            color={Color.secondary_color}
          >
            <CoreValueText>{CommonString.process}</CoreValueText>
          </CoreValueInnerContainer>
          <CoreValueTitleForMobileView>
            <h2>{CommonString.process}</h2>
          </CoreValueTitleForMobileView>
        </Grid>
        <Grid item md={10}>
          <CoreValueFlexContainer>
            <CoreValueInnerContainer color={Color.primary_color}>
              <img src={img_1} alt="" />
              <h4>{CommonString.sa_services_process_1}</h4>
              <p>{CommonString.sa_services_process_1_text}</p>
            </CoreValueInnerContainer>
            <CoreValueInnerContainer color={Color.secondary_color}>
              <img src={img_2} alt="" />
              <h4>{CommonString.sa_services_process_2}</h4>
              <p>{CommonString.sa_services_process_2_text}</p>
            </CoreValueInnerContainer>
            <CoreValueInnerContainer color={Color.primary_color}>
              <img src={img_3} alt="" />
              <h4>{CommonString.sa_services_process_3}</h4>
              <p>{CommonString.sa_services_process_3_text}</p>
            </CoreValueInnerContainer>
            <CoreValueInnerContainer color={Color.secondary_color}>
              <img src={img_4} alt="" />
              <h4>{CommonString.sa_services_process_4}</h4>
              <p>{CommonString.sa_services_process_4_text}</p>
            </CoreValueInnerContainer>
          </CoreValueFlexContainer>
        </Grid>
        <Grid item md={1}>
          <CoreValueInnerContainer
            mobileView={true}
            color={Color.primary_color}
          ></CoreValueInnerContainer>
        </Grid>
      </Grid>
    </CoreValueContainer>
  );
}
