import React from "react";
import { BannerImg, BannerContainer } from "../../style/common.style";
import { BannerText, BannerTitle } from "../../style/home.style";
import { CommonString } from "../../utils/CommonString";
import bannerImgForAbout from "../../assets/images/bannerImgForAbout.svg";
import bannerImgForAboutMobile from "../../assets/images/bannerImgForAboutMobile.svg";
import { StaffAugmentationBannerContainer } from "../../style/service.style";

export default function BannerSection() {
  return (
    <StaffAugmentationBannerContainer>
      <BannerImg className="viewOnlyInLaptop" src={bannerImgForAbout} />
      <BannerImg className="viewOnlyInMobile" src={bannerImgForAboutMobile} />
      <BannerContainer moreMargin={true}>
        <BannerTitle>{CommonString.about_banner_title}</BannerTitle>
        <BannerText>{CommonString.about_banner_text}</BannerText>
      </BannerContainer>
    </StaffAugmentationBannerContainer>
  );
}
