import { Grid } from "@mui/material";
import React from "react";
import {
  OurMissionContainer,
  OurVisionContainer,
} from "../../style/about.style";
import { CommonString } from "../../utils/CommonString";
import missionImg from "../../assets/images/missionImg.svg";
import visionImg from "../../assets/images/visionImg.svg";

export default function MissionVisionSection() {
  return (
    <Grid container>
      <Grid item md={6} xs={12}>
        <OurVisionContainer>
          <img src={visionImg} alt="" />
          <h2>{CommonString.our_mission}</h2>
          <p>{CommonString.our_mission_text}</p>
        </OurVisionContainer>
      </Grid>
      <Grid item md={6} xs={12}>
        <OurMissionContainer>
          <img src={missionImg} alt="" />
          <h2>{CommonString.our_vision}</h2>
          <p>{CommonString.our_vision_text}</p>
        </OurMissionContainer>
      </Grid>
    </Grid>
  );
}
