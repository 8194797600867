import React, { useRef } from "react";
import { StaffAugmentationBannerContainer } from "../../../style/service.style";
import {
  BannerContainer,
  BannerImg,
  Container,
} from "../../../style/common.style";
import { BannerText, BannerTitle } from "../../../style/home.style";
import { CommonString } from "../../../utils/CommonString";
import { ContactImg } from "../../../style/contct.style";
import service_img_2 from "../../../assets/images/service_img_2.svg";
import service_img_1_mobile from "../../../assets/images/service_img_1_mobile.svg";
import MagneticHeaderButton from "../../../component/Button";
import { useDispatch } from "react-redux";
import UseHover from "../../../component/UseHover";
import { onChangeActiveMenuStatus } from "../../../store/CommonStore";
export default function ITConsultingBannerSection() {
  const dispatch = useDispatch();
  const [hoverRef] = UseHover();
  const parent = useRef(0);
  function onClick() {
    window.scrollTo(0, 0);
    dispatch(onChangeActiveMenuStatus(CommonString.contact));
    localStorage.setItem("activeMenu", CommonString.contact);
  }
  return (
    <StaffAugmentationBannerContainer>
      <BannerImg className="viewOnlyInLaptop" src={service_img_2} />
      <BannerImg className="viewOnlyInMobile" src={service_img_1_mobile} />
      <BannerContainer>
        <BannerTitle>{CommonString.service_2_title}</BannerTitle>
        <BannerText>{CommonString.it_consulting_text}</BannerText>
        <div className="mt-1">
          <MagneticHeaderButton
            isLightBackground={true}
            text={CommonString.lets_strategize}
            ref={hoverRef}
            parent={parent}
            onClick={() => onClick()}
          />
        </div>
      </BannerContainer>
    </StaffAugmentationBannerContainer>
  );
}
