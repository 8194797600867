import React from "react";
import {
  BottomContainer,
  CertificateImg,
  CertificateImgContainer,
  ContactInfoWithLogo,
  FooterInnerFirstContainer,
  FooterMainContainer,
  MiddleContainer,
  Title,
} from "../style/footer.style";
import { Grid } from "@mui/material";
import { CommonString } from "../utils/CommonString";
import { Link } from "react-router-dom";
import { Icon } from "@iconify/react";
import VectorLogoForFooter from "../assets/logo/VectorLogoForFooter.svg";
import e_verfiy from "../assets/certifications/e_verify.png";
import img_1 from "../assets/certifications/1.svg";
import img_2 from "../assets/certifications/2.svg";
import img_3 from "../assets/certifications/3.svg";
import { onClickScrollTop } from "../utils/Function";
export default function Footer() {
  return (
    <FooterMainContainer>
      <span
        style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-between",
          height: "-webkit-fill-available",
        }}
      >
        <div>
          <FooterInnerFirstContainer>
            <div>
              <h4>How can we help?</h4>
              <p>
                We’re here to help! Whether you have questions about our
                services,
                <br /> need support, or want to discuss how we can assist you in
                achieving your goals,
                <br /> we’d love to hear from you.
              </p>
            </div>
            <span>
              <p style={{ display: "flex", alignItems: "center" }}>
                <Link onClick={() => onClickScrollTop()} to="/Contact">
                  Let’s Strategize <Icon icon="lineicons:arrow-right" />
                </Link>
              </p>
            </span>
          </FooterInnerFirstContainer>
          <MiddleContainer>
            <div style={{ paddingRight: 25, width: "30%" }}>
              <Title>{CommonString.it_consulting}</Title>
              <p>Data Management Services</p>
              <p>Business Support Services</p>
              <p>Healthcare IT Solutions</p>
              <p>IT Solutions</p>
              <p style={{ paddingTop: 20 }}>
                <Link onClick={() => onClickScrollTop()} to="/ITConsulting">
                  Explore <Icon icon="rivet-icons:arrow-right" />
                </Link>
              </p>
            </div>
            <div style={{ paddingRight: 25, width: "25%" }}>
              <Title>{CommonString.staff_augmentation}</Title>
              <p>Temporary Staffing</p>
              <p>Permanent Staffing</p>
              <p style={{ paddingTop: 20 }}>
                <Link
                  onClick={() => onClickScrollTop()}
                  to="/StaffAugmentation"
                >
                  Explore <Icon icon="rivet-icons:arrow-right" />
                </Link>
              </p>
            </div>
            <div style={{ paddingRight: 25, width: "10%" }}>
              <Title>Info</Title>
              <p>
                <Link onClick={() => onClickScrollTop()} to={"/About"}>
                  {CommonString.about}
                </Link>
              </p>
              <p>
                <Link onClick={() => onClickScrollTop()} to={"/Contact"}>
                  {CommonString.contact}
                </Link>
              </p>
            </div>
            <div style={{ width: "50%" }}>
              <CertificateImgContainer displayInMobileView={true}>
                <div style={{ display: "flex", alignItems: "center" }}>
                  <CertificateImg src={img_1} alt="" />
                  <CertificateImg src={img_3} alt="" />
                </div>
                <CertificateImg src={img_2} alt="" />
              </CertificateImgContainer>
              <CertificateImgContainer>
                <CertificateImg src={img_1} alt="" />
                <CertificateImg src={img_2} alt="" />
                <CertificateImg src={img_3} alt="" />
              </CertificateImgContainer>
            </div>
            <div style={{ width: "20%" }}>
              <ContactInfoWithLogo>
                <img src={VectorLogoForFooter} alt="" />
                <img
                  style={{ width: "100px", height: "auto" }}
                  src={e_verfiy}
                  alt=""
                />
              </ContactInfoWithLogo>
            </div>
          </MiddleContainer>
        </div>
        <BottomContainer>
          <p>
            © {new Date().getFullYear()} — E7strategies Inc. All rights
            reserved.{" "}
          </p>
          <span
            style={{
              display: "flex",
              whiteSpace: "nowrap",
              marginBottom: 10,
              marginTop: 10,
            }}
          >
            <a href="tel:+1-202-957-5358">+1 (202)-957-5358 </a>
            <span style={{ margin: "0 10px", marginBottom: 0 }}>|</span>
            <a href="mailto:info@e7strategies.com"> info@e7strategies.com</a>
          </span>
          <span>
            <p>
              <Link style={{ marginBottom: 0 }} to={"/CookiePolicy"}>
                {CommonString.cookie_policy}
              </Link>
            </p>
            <p>
              <Link to={"/PrivacyPolicy"}>{CommonString.privacy_policy}</Link>
            </p>
            <div
              style={{ marginBottom: 10 }}
              onClick={() => window.scrollTo({ top: 0, behavior: "smooth" })}
            >
              <Icon icon="lineicons:arrow-up" />
            </div>
          </span>
        </BottomContainer>
      </span>
    </FooterMainContainer>
  );
}

{
  /* <FooterContainer ref={parentRef}>
      <VectorLogo>
        <svg
          ref={svgRef}
          viewBox="0 0 776 692"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path d="M507.647 0.0366211H0.814505L0.814478 172.869H461.337L507.647 0.0366211Z" />
          <path d="M729.542 172.869L775.093 2.86952C775.476 1.44021 774.399 0.0366211 772.919 0.0366211H596.924L550.614 172.869L527.459 259.285L504.304 345.701L482.723 426.24L457.993 518.534L437.988 593.195L411.683 691.366H590.611L616.916 593.195L636.921 518.534L661.651 426.24L683.231 345.701L706.386 259.285L729.542 172.869Z" />
          <path d="M0.814478 345.701V426.24H393.446L415.026 345.701L438.181 259.285H0.814478V345.701Z" />
          <path d="M348.711 593.195L368.716 518.534H0.814478L0.814453 593.195V691.366H322.406L348.711 593.195Z" />
        </svg>
      </VectorLogo>
      <div style={{ display: "flex", width: "100vw", padding: "5vmax 4vmax" }}>
        <LeftSideContainer style={{ width: "50%", height: "100%" }}>
          <span
            style={{
              background: Color.secondary_color,
              borderRadius: 20,
              width: "100%",
              height: "70vh",
            }}
          >
            <div>
              <img
                style={{ margin: "30px", width: "400px" }}
                src={FooterLogo}
                alt=""
              />
              <img
                style={{ margin: "20px 30px", width: "-webkit-fill-available" }}
                src={img_3}
                alt=""
              />
            </div>
            <div style={{ marginBottom: 20 }}>
              <p>Let’s</p>
              <p>Strategize</p>
            </div>
            <div class="cutout_1"></div>
            <div class="cutout_2"></div>
            <div class="cutout_3"></div>
          </span>
          <LeftSideIconContainer>
            <Icon icon="streamline:mail-send-email-message" />
          </LeftSideIconContainer>
        </LeftSideContainer>
        <MiddleContentContainer>
          <ContactInfoContainer>
            <h4>{CommonString.contact_us}</h4>
            <p>
              <img alt="" src={Mail} />
              {CommonString.email_id}
            </p>
            <p>
              <img alt="" src={Phone} />
              {CommonString.phone_number}
            </p>
            <p>
              <img alt="" src={Linkedin} />
              {CommonString.linkedin}
            </p>
          </ContactInfoContainer>
          <ContactInfoContainer>
            <h4>
              <b>Visit Us</b>
            </h4>
            <LocationBox>
              <img alt="" src={map} />
              <div>
                <p>
                  <b>{CommonString.company_location}</b>
                </p>
                <p>
                  <b>{CommonString.company_pin_code}</b>
                </p>
              </div>
            </LocationBox>
          </ContactInfoContainer>
        </MiddleContentContainer>
        <div
          style={{
            width: "20%",
            height: "70vh",
            marginLeft: "4vw",
          }}
        >
          <img
            style={{
              borderRadius: 20,
              objectFit: "cover",
              height: "100%",
              width: "100%",
            }}
            src={footer1}
            alt=""
          />
        </div>
      </div>
    </FooterContainer> */
}
