import React from "react";

export default function Loader() {
  window.addEventListener("load", function () {
    setTimeout(function () {
      //   document.getElementById("loader").style.display = "none";
    }, 1500); // Same time as animation duration (1.5s)
  });
  window.addEventListener("load", function () {
    setTimeout(function () {
      //   document.getElementById("loader1").style.display = "none";
    }, 1500); // Same time as animation duration (1.5s)
  });

  return (
    <>
      <div id="loader"></div>
      <div id="loader1"></div>
      <div id="loader2"></div>
    </>
  );
}
