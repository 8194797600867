import { Grid } from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import { CommonString } from "../../utils/CommonString";
import {
  ClientImgContainer,
  WhoWeSupportContentContainer,
  WhoWeSupportSectionContainer,
} from "../../style/home.style";
import client_img_1 from "../../assets/clients/1.png";
import client_img_2 from "../../assets/clients/2.png";
import client_img_3 from "../../assets/clients/3.png";
import client_img_4 from "../../assets/clients/4.png";
import client_img_5 from "../../assets/clients/5.png";
import client_img_6 from "../../assets/clients/6.png";
import client_img_7 from "../../assets/clients/7.png";
import client_img_8 from "../../assets/clients/8.png";
import client_img_9 from "../../assets/clients/9.png";
import client_img_10 from "../../assets/clients/10.png";
import client_img_11 from "../../assets/clients/11.png";
import client_img_12 from "../../assets/clients/12.png";
import client_img_13 from "../../assets/clients/13.png";
import client_img_14 from "../../assets/clients/14.png";
import client_img_15 from "../../assets/clients/15.png";
import client_img_16 from "../../assets/clients/16.png";
import client_img_17 from "../../assets/clients/17.png";
import client_img_18 from "../../assets/clients/18.png";
import client_img_19 from "../../assets/clients/19.png";
import client_img_20 from "../../assets/clients/20.png";
import client_img_21 from "../../assets/clients/21.png";
import client_img_22 from "../../assets/clients/22.png";
import client_img_23 from "../../assets/clients/23.png";
import client_img_24 from "../../assets/clients/24.png";
import client_img_25 from "../../assets/clients/25.png";
import client_img_26 from "../../assets/clients/26.png";
import client_img_27 from "../../assets/clients/27.png";
import client_img_28 from "../../assets/clients/28.png";
import client_img_29 from "../../assets/clients/29.png";
import client_img_30 from "../../assets/clients/30.png";
import gsap from "gsap";

export default function WhoWeSupportSection() {
  const [isActive, setIsActive] = useState(false);
  const containerRef = useRef(null);
  const leftColRef = useRef(null);

  useEffect(() => {
    if (window.innerWidth < 600) {
      return;
    }
    gsap.to(leftColRef.current, {
      yPercent: 0,
      ease: "none",
      scrollTrigger: {
        trigger: containerRef.current,
        start: "top top",
        end: "bottom +100",
        onLeave: function () {
          setIsActive(false);
        },
        onEnter: function () {
          setIsActive(true);
        },
        onEnterBack: function () {
          setIsActive(true);
        },
        scrub: true,
      },
    });
  }, []);

  function ClientImg(props) {
    return (
      <ClientImgContainer item md={4} xs={6}>
        <img src={props.img} alt="" />
      </ClientImgContainer>
    );
  }
  return (
    <WhoWeSupportSectionContainer>
      <Grid container className="innerContainer">
        <Grid item md={4} xs={12} className="viewOnlyInMobile">
          <WhoWeSupportContentContainer>
            <h2>
              {CommonString.who_we} <span>{CommonString.support}</span>
            </h2>
            <p>{CommonString.who_we_support_text_1}</p>
          </WhoWeSupportContentContainer>
        </Grid>
        <Grid item md={8} xs={12}>
          <Grid container ref={leftColRef}>
            <ClientImg img={client_img_1} />
            <ClientImg img={client_img_2} />
            <ClientImg img={client_img_3} />
            <ClientImg img={client_img_4} />
            <ClientImg img={client_img_5} />
            <ClientImg img={client_img_6} />
            <ClientImg img={client_img_7} />
            <ClientImg img={client_img_8} />
            <ClientImg img={client_img_9} />
            <ClientImg img={client_img_10} />
            <ClientImg img={client_img_11} />
            <ClientImg img={client_img_12} />
            <ClientImg img={client_img_13} />
            <ClientImg img={client_img_14} />
            <ClientImg img={client_img_15} />
            <ClientImg img={client_img_16} />
            <ClientImg img={client_img_17} />
            <ClientImg img={client_img_18} />
            <ClientImg img={client_img_19} />
            <ClientImg img={client_img_20} />
            <ClientImg img={client_img_21} />
            <ClientImg img={client_img_22} />
            <ClientImg img={client_img_23} />
            <ClientImg img={client_img_24} />
            <ClientImg img={client_img_25} />
            <ClientImg img={client_img_26} />
            <ClientImg img={client_img_27} />
            <ClientImg img={client_img_28} />
            <ClientImg img={client_img_29} />
            <ClientImg img={client_img_30} />
          </Grid>
        </Grid>
        <Grid item md={4} xs={12} className="viewOnlyInLaptop">
          <WhoWeSupportContentContainer
            style={{
              position: isActive ? "sticky" : "inherit",
              top: 110,
            }}
            isActive={isActive}
          >
            <h2>{CommonString.who_we}</h2>
            <h2>
              <span>{CommonString.support}</span>
            </h2>
            <p>{CommonString.who_we_support_text_1}</p>
            {/* <p>{CommonString.who_we_support_text_2}</p> */}
          </WhoWeSupportContentContainer>
        </Grid>
      </Grid>
    </WhoWeSupportSectionContainer>
  );
}
