export var Color = {
  primary_color: "#143243",
  secondary_color: "#88ABB8",
  light_text: "#F3F3F3",
  background: "#FBFBFE",
  accent: "#DC6D41",
  button_secondary: "#CBCBCC",
  button_primary: "#143243",
  secondary_text_color: "#757575",
  dark_grey_opacity: "color(display-p3 1 1 1 / 0.27)",
  footer_container_color: "#7EACB9",
  contact_background_color: "#E9ECEE",
  header_border_color: "#B2B2B2",
  background_1: "#516573",
  background_2: "#929FA8",
  background_3: "#BEC5CB",
  background_4: "#3B5262",
  background_5: "#7C8C96",
  background_6: "#A8B2B9",
  card_text_color: "#213948",
  li_text_color: "#083B4C",
};
