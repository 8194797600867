import React from "react";
import { InputContainer } from "../style/common.style";
import { Color } from "../utils/Color";

export default function Input(props) {
  return (
    <InputContainer>
      {props.multiline ? (
        <textarea {...props} />
      ) : (
        <input
          autocomplete="off"
          style={{
            height: props.multiline ? "40px" : "inherit",
            borderBottomColor: !props.required
              ? Color.secondary_text_color
              : Color.accent,
          }}
          {...props}
          placeholder={props.placeholder}
        />
      )}
    </InputContainer>
  );
}
