export const addLineBreak = (str) =>
  str.split("\n").map((subStr) => {
    return (
      <>
        {subStr}
        <br />
      </>
    );
  });
export function onClickScrollTop() {
  window.scrollTo({ top: 0, behavior: "smooth" });
}
