import React, { useEffect } from "react";
import ITConsultingBannerSection from "./ITConsultingBannerSection";
import SubServiceSection from "./SubServiceSection";
import WhoWeSupportSection from "../../home/WhoWeSupportSection";
import NaicsCodeSection from "../NaicsCodes";
import ITServiceProcess from "./ITServiceProcess";
import Loader from "../../../component/Loader";
import { useDispatch } from "react-redux";
import { onChangeLightHeader } from "../../../store/CommonStore";
// import ContractVehicles from "../ContractVehicles";

export default function ITConsultingScreen() {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(onChangeLightHeader(true));
  }, []);

  return (
    <>
      <Loader />
      <ITConsultingBannerSection />
      <SubServiceSection />
      <WhoWeSupportSection />
      <NaicsCodeSection />
      <ITServiceProcess />
    </>
  );
}
