import { Grid } from "@mui/material";
import React from "react";
import {
  NaicsCodeCardContainer,
  NaicsCodeContainer,
  NaicsCodeTitle,
} from "../../style/home.style";
import { CommonString } from "../../utils/CommonString";

export default function NaicsCodeSection() {
  var data = [
    {
      code: "518210",
      title: "Data Processing, Hosting, and Related Services",
      isLight: true,
    },
    {
      code: "541511",
      title: "Custom Computer Programming Services",
      isLight: false,
    },
    {
      code: "541519",
      title: "Other Computer Related Services",
      isLight: true,
    },
    {
      code: "541611",
      title:
        "Administrative Management and General Management Consulting Services",
      isLight: true,
    },
    {
      code: "541612",
      title: "Human Resources Consulting Services",
      isLight: false,
    },
    {
      code: "541618",
      title: "Other Management Consulting Services",
      isLight: true,
    },
    {
      code: "541690",
      title: "Other Scientific and Technical Consulting Services",
      isLight: false,
    },
    {
      code: "541990",
      title: "All Other Professional, Scientific, and Technical Services",
      isLight: false,
    },
    {
      code: "561300",
      title: "Employment Services",
      isLight: true,
    },
    {
      code: "561310",
      title: "Employment Placement Agencies",
      isLight: false,
    },
    {
      code: "561320",
      title: "Temporary Help Services",
      isLight: true,
    },
  ];
  var data_for_mobile_view = [
    {
      code: "518210",
      title: "Data Processing, Hosting, and Related Services",
      isLight: true,
    },
    {
      code: "541511",
      title: "Custom Computer Programming Services",
      isLight: false,
    },
    {
      code: "541519",
      title: "Other Computer Related Services",
      isLight: true,
    },
    {
      code: "541611",
      title:
        "Administrative Management and General Management Consulting Services",
      isLight: false,
    },
    {
      code: "541612",
      title: "Human Resources Consulting Services",
      isLight: true,
    },
    {
      code: "541618",
      title: "Other Management Consulting Services",
      isLight: false,
    },
    {
      code: "541690",
      title: "Other Scientific and Technical Consulting Services",
      isLight: true,
    },
    {
      code: "541990",
      title: "All Other Professional, Scientific, and Technical Services",
      isLight: false,
    },
    {
      code: "561300",
      title: "Employment Services",
      isLight: true,
    },
    {
      code: "561310",
      title: "Employment Placement Agencies",
      isLight: false,
    },
    {
      code: "561320",
      title: "Temporary Help Services",
      isLight: true,
    },
  ];
  function NaicsCodeCard(props) {
    return (
      <NaicsCodeCardContainer isLight={props.isLight}>
        <div>{props.code}</div>
        <h3>{props.code}</h3>
        <p>{props.name}</p>
      </NaicsCodeCardContainer>
    );
  }
  return (
    <NaicsCodeContainer container>
      <Grid item md={3} xs={12}>
        <NaicsCodeTitle>
          <span>{CommonString.naics}</span>
          <br />
          <h2>{CommonString.naics}</h2>
          <p>{CommonString.codes}</p>
        </NaicsCodeTitle>
      </Grid>
      {data.map((singleData) => (
        <Grid item md={3} xs={12} className="viewOnlyInLaptop">
          <NaicsCodeCard
            isLight={singleData.isLight}
            code={singleData.code}
            name={singleData.title}
          />
        </Grid>
      ))}
      {data_for_mobile_view.map((singleData) => (
        <Grid item md={3} xs={12} className="viewOnlyInMobile">
          <NaicsCodeCard
            isLight={singleData.isLight}
            code={singleData.code}
            name={singleData.title}
          />
        </Grid>
      ))}
    </NaicsCodeContainer>
  );
}
