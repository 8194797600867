import React, { useEffect } from "react";
import BannerSection from "./BannerSection";
import WhoWeAreSection from "./WhoWeAreSection";
import ServiceSection from "./ServiceSection";
import WhoWeSupportSection from "./WhoWeSupportSection";
import Loader from "../../component/Loader";
import { useDispatch } from "react-redux";
import { onChangeLightHeader } from "../../store/CommonStore";

export default function HomeScreen() {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(onChangeLightHeader(true));
  }, []);

  return (
    <>
      <Loader />
      <BannerSection />
      <WhoWeAreSection />
      <ServiceSection />
      <WhoWeSupportSection />
    </>
  );
}
