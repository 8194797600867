import styled from "styled-components";
import { Color } from "../utils/Color";
import { Grid } from "@mui/material";
import { CommonString } from "../utils/CommonString";
import circle from "../assets/icon/circle.svg";

const ServiceContainer = styled.div`
  height: 100vh;
  /* display: none;
  @media (${CommonString.mobile_screen}) {
    width: 100%;
    display: block;
  } */
`;
const StaffAugmentationBannerContainer = styled.div`
  /* background-repeat: no-repeat; */
  /* background-size: cover; */
  /* width: 100vw; */
  /* height: 100vh; */
  /* background-position: center; */
  height: 100vh;
  width: 100vw;
  display: flex;
  background-color: #022435;
  justify-content: center;
  flex-direction: column;
  div:first {
    max-width: 980px;
  }
  a {
    margin-top: 10%;
  }
  @media (${CommonString.mobile_screen}) {
    background-image: none;
    height: auto;
    background-color: transparent;
    margin-top: 8vh;
  }
`;
const ITConsultingBannerContainer = styled.div`
  /* background-repeat: no-repeat;
  background-size: cover; */
  /* width: 100vw; */
  /* height: 100vh; */
  /* width: 100vw; */
  /* background-color: red; */
  /* background-position: center; */
  div:first {
    max-width: 980px;
  }
  a {
    margin-top: 10%;
  }
  @media (${CommonString.mobile_screen}) {
    background-image: none;
    height: auto;
  }
`;
const ServiceCardContainer = styled.div`
  margin-top: 5vh;
  div {
    height: 18rem;
    background-color: ${(props) => props.background};
    color: ${(props) =>
      props.light ? Color.card_text_color : Color.background};
    padding: 4vh;
    border-radius: 15px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    h3 {
      margin: 0;
    }
  }
  @media (${CommonString.mobile_screen}) {
    margin-top: 3vh;
  }
`;
const ContractVehicleMainContainer = styled(Grid)`
  padding: 0 ${CommonString.common_screen_padding};
  padding-bottom: 4.5vmax;
  h4 {
    font-size: 1.1rem;
  }
  h2 span {
    color: ${Color.accent};
  }
  div div {
    border: 1px solid #70aebb;
    border-radius: 0.8125rem;
    background: var(--Color-7, #e7ecee);
    background: var(--Color-7, color(display-p3 0.9137 0.9255 0.9333));
    padding: 4vh;
    height: 88%;
    transition: all 0.7s ease-out;

    ul {
      list-style: none;
      background-position: left 0px;
      padding: 0;
    }
    li {
      color: ${Color.primary_color};
      list-style-type: none;
      margin-bottom: 1rem;
      background: url(${circle}) no-repeat left top;
      padding: 0px 0 0px 28px;
      margin-top: -5px;
    }
    img {
      width: 100%;
    }
    &:hover {
      box-shadow: 0px 81px 80px 0px color(display-p3 0 0 0 / 0.06),
        0px 16.2px 13px 0px color(display-p3 0 0 0 / 0.03);
    }
  }
  @media (${CommonString.mobile_screen}) {
    div div {
      padding: 2vmax;
      height: auto;
    }
  }
`;
export {
  ContractVehicleMainContainer,
  ServiceCardContainer,
  ServiceContainer,
  StaffAugmentationBannerContainer,
  ITConsultingBannerContainer,
};
