import { createSlice } from "@reduxjs/toolkit";
import { CommonString } from "../utils/CommonString";
// import { onChangeIsLoading } from "./LevelReduxStore";

export const CommonStore = createSlice({
  name: "CommonStore",
  initialState: {
    active_menu_status: CommonString.home,
    lightHeader: true,
    is_open_service_dropDown: false,
    is_open_mobile_nav_bar: false,
  },
  reducers: {
    onChangeActiveMenuStatus: (state, action) => {
      state.active_menu_status = action.payload;
    },
    onChangeLightHeader: (state, action) => {
      state.lightHeader = action.payload;
    },
    onChangeIsOpenServiceDropDown: (state, action) => {
      state.is_open_service_dropDown = action.payload;
    },
    onChangeIsOpenMobileNavBar: (state, action) => {
      state.is_open_mobile_nav_bar = action.payload;
    },
  },
});

export const {
  onChangeIsOpenMobileNavBar,
  onChangeActiveMenuStatus,
  onChangeLightHeader,
  onChangeIsOpenServiceDropDown,
} = CommonStore.actions;

export default CommonStore.reducer;
