import React from "react";
import {
  ServiceDropDownMainContainer,
  ServiceMenuImg,
} from "../style/header.style";
import { CommonString } from "../utils/CommonString";
import { Link } from "react-router-dom";
import ServiceMenuImg1 from "../assets/images/ServiceMenuImg1.svg";
import ServiceMenuImg2 from "../assets/images/service_img_22.svg";
import { Icon } from "@iconify/react";

export default function ServiceDropDown(props) {
  return (
    <ServiceDropDownMainContainer open={props.open}>
      <div className="firstDiv">
        <ServiceMenuImg morePadding={true} alt="" src={ServiceMenuImg1} />
        <div>
          <h4>{CommonString.it_consulting}</h4>
          <span>
            <div>
              <p>
                <b>Data Management Services</b>
              </p>
              <p>Database/storage management</p>
              <p>Big data & analytics services</p>
              <p>Cloud architecture</p>
            </div>
            <div>
              <p>
                <b>Business Support Services</b>
              </p>
              <p>Business strategy and planning support</p>
              <p>Training in IT applications</p>
            </div>
          </span>
          <span>
            <div>
              <p className="mt-1">
                <b>Healthcare IT Solutions</b>
              </p>
              <p>Medical Data Analysis</p>
              <p>EEG/MRI Research lab IT solutions</p>
              <p>Clinical database development</p>
            </div>
            <div>
              <p className="mt-1">
                <b>IT Solutions</b>
              </p>
              <p>IT Architecting solutions</p>
              <p>Cyber Security</p>
              <p>Project management</p>
              <p>IT Hardware and Software</p>
            </div>
          </span>
          <Link to="/ITConsulting">
            Explore <Icon icon="rivet-icons:arrow-right" />
          </Link>
        </div>
      </div>
      <div className="SecondDiv">
        <ServiceMenuImg alt="" src={ServiceMenuImg2} />
        <div>
          <h4>{CommonString.staff_augmentation}</h4>
          <span>
            <div>
              <p>
                <b>Temporary Staffing</b>
              </p>
              <p>Short-Term Staffing</p>
              <p>Project-Based Staffing</p>
              <p>Shift-Based Staffing</p>
            </div>
          </span>
          <span>
            <div className="mt-1">
              <p>
                <b>Permanent Staffing</b>
              </p>
              <p>Temp-to-Perm Staffing</p>
              <p>Direct-Hire Staffing</p>
              <p>Part-Time Staffing</p>
              <p>Long-Term Staffing</p>
            </div>
          </span>
        </div>
        <Link to="/StaffAugmentation">
          Explore <Icon icon="rivet-icons:arrow-right" />
        </Link>
      </div>
      {/*  */}
    </ServiceDropDownMainContainer>
  );
}
