import styled, { keyframes } from "styled-components";
import { CommonString } from "../utils/CommonString";
import { Color } from "../utils/Color";

const HeaderContainer = styled.div`
  position: fixed;
  border-bottom: 1px solid ${Color.accent};
  padding: 1vmax ${CommonString.common_screen_padding};
  padding-top: ${(props) => (props.lightHeader ? "1.4vmax" : "1vmax")};
  padding-bottom: ${(props) => (props.lightHeader ? "1.4vmax" : "1vmax")};
  display: flex;
  justify-content: space-between;
  width: -webkit-fill-available;
  align-items: center;
  z-index: 10;
  transition: top 0.2s ease-in-out;
  top: ${(props) => (props.isVisible ? 0 : "-14vh")};
  background-color: ${(props) =>
    props.lightHeader ? "transparent" : Color.contact_background_color};
  /* backdrop-filter: ${(props) =>
    props.lightHeader ? "blur(78px)" : "none"}; */
  /* background: var(--background-opaque);
  -webkit-backdrop-filter: blur(8px);
  backdrop-filter: blur(8px); */
  /* width: 100vw; */
  border-bottom-width: ${(props) => (props.lightHeader ? "0" : "1px")};
  border-bottom-style: solid;
  border-image-slice: 1;
  border-image-source: linear-gradient(to right, #e9ecee, #dc6d41, #e9ecee);
  opacity: 1;
  img {
    width: 16vw;
    cursor: pointer;
  }
  /* a img {
    width: auto;
    padding: 0;
    margin-top: 0;
  } */
  @media (${CommonString.mobile_screen}) {
    display: none;
  }
`;
const BlurDiv = styled.div`
  /* position: fixed;
  top: 0;
  filter: blur(8px);
  background-color: #0000005c;
  height: 5vmax;
  width: 100vw; */
`;
const HeaderListContainer = styled.div`
  border-radius: 50px;
  padding: 10px 25px;
  display: flex;
  align-items: center;
  height: max-content;
  position: relative;
  p {
    color: ${(props) =>
      props.lightHeader ? Color.background : Color.primary_color};
    cursor: pointer;
  }
  a {
    margin: 0 30px;
  }
`;
const HeaderButtonContainer = styled.div`
  display: flex;
  align-items: center;
  div:first-child {
    /* margin-right: 20px; */
  }
`;
const MenuDropDownContainer = styled.div`
  position: relative;
  margin: 0 25px;
  p:first-child {
    display: flex;
    align-items: center;
  }
  .arrow {
    width: 45px; /* Adjust width as needed */
    height: 25px; /* Adjust height as needed */
    background-color: #9dbbc980; /* The color of the arrow */
    position: absolute;
    bottom: ${window.innerHeight >= 700
      ? "-4vmax"
      : "-3vmax"}; /* Positioning the arrow */
    right: -35px;
    opacity: ${(props) => (props.open ? 1 : 0)};
    transform: translateX(-50%);
    /* transition: opacity 0s ease-out; */
    clip-path: polygon(
      50% 0%,
      0% 100%,
      100% 100%
    ); /* Creates a triangle shape */
  }
  img {
    transition: 0.2s ease-in-out;
    width: 20px;
    margin-left: 5px;
    margin-top: 0.5vh;
    rotate: ${(props) => (!props.open ? "360deg" : "180deg")};
  }
`;
const MenuDropDown = styled.div`
  display: ${(props) => (props.open ? "block" : "none")};
  position: absolute;
  background-color: ${Color.background};
  color: ${Color.primary_color} !important;
  top: 7vh;
  width: 18vw;
  border-radius: 10px;
  right: 0;
  p {
    padding: 0px 2vw;
    color: ${Color.primary_color} !important;
    white-space: nowrap;
    margin: 10px;
    border-radius: 10px;
    &:hover {
      background-color: ${Color.secondary_color};
    }
  }
`;
const HeaderMainContainer = styled.div`
  /* display: ${(props) => (props.isVisible ? "inherit" : "none")}; */
`;
const MenuItem = styled.p`
  font-weight: ${(props) => (props.activeStatus ? "bold" : "inherit")};
  display: flex;
  align-items: center;
  font-size: 16px;
`;
const MobileNavBarContainer = styled.div`
  background-color: ${Color.background};
  padding: 2vmax ${CommonString.common_screen_padding};
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: fixed;
  top: 0;
  z-index: 100;
  /* height: 8vh; */
  width: -webkit-fill-available;
  img {
    width: 200px;
  }
`;
const NavBar = styled.div`
  position: absolute;
  height: 100vh;
  width: 100vw;
  background-color: ${Color.background};
  z-index: 99;
  overflow: hidden;
  transition: 0.5s ease-in-out;
  top: ${(props) => (props.isOpenModal ? 0 : "-100vh")};
  hr {
    margin-top: 8vh;
    color: ${Color.accent};
  }
  p {
    padding: 2vmax ${CommonString.common_screen_padding};
    font-size: 3vh;
    color: ${Color.primary_color};
    opacity: ${(props) => (props.isOpenModal ? 1 : 0.5)};
  }
`;
const ServiceDropDownMainContainer = styled.div`
  background: #9dbbc980;
  border-radius: 15px;
  padding: 5vh;
  display: flex;
  position: absolute;
  top: 7vh;
  left: 4vmax;
  right: 4vmax;
  backdrop-filter: blur(8.649999618530273px);
  transition: max-height 0.1s ease-out, opacity 0.1s ease-out;
  max-height: ${(props) => (props.open ? "80vh" : "0vh")};
  opacity: ${(props) => (props.open ? 1 : 0)};
  margin-top: 3.6vmax;
  .firstDiv {
    background-color: white;
    position: relative;
    border-radius: 15px;
    padding: 5vh;
    /* padding: ${(props) => (props.open ? "5vh" : 0)}; */
    width: 60%;
    /* transition: 0.2s ease-out; */
    overflow: hidden;
    /* height: 100%; */
    span {
      div {
        width: 50%;
      }
    }
  }
  .SecondDiv {
    margin-left: 2vw;
    background-color: white;
    position: relative;
    border-radius: 15px;
    /* padding: ${(props) => (props.open ? "5vh" : 0)}; */
    padding: 5vh;
    width: 40%;
    overflow: hidden;
    /* height: 100%; */
  }
  h4 {
    margin: 0;
    margin-bottom: 4vh;
    color: ${Color.primary_color};
  }
  span {
    display: flex;
    p {
      line-height: 2vmax;
      color: #757575 !important;
      font-size: 1.1vmax !important;
      padding: 0;
    }

    b {
      color: ${Color.primary_color};
      font-size: 1.2vmax !important;
      /* margin-top: 4vh; */
    }
  }
  a {
    width: 100%;
    display: flex;
    align-items: center;
    color: ${Color.accent};
    font-weight: bold;
    margin-top: 2vh; /* justify-content: center; */
    font-size: 14px;
    svg {
      margin-left: 5px;
    }
  }
`;
const ServiceMenuImg = styled.img`
  height: 200px !important;
  width: 200px !important;
  position: absolute !important;
  right: ${(props) => (props.morePadding ? "-50px" : "-30px")} !important;
  bottom: -20px !important;
  z-index: 0 !important;
  /* opacity: ${(props) => (props.open ? 1 : 0)}; */
  /* transition-delay: 2s; */
  /* animation-iteration-count: infinite;
  transition: opacity 4s ease-out !important; */
`;
export {
  ServiceMenuImg,
  ServiceDropDownMainContainer,
  NavBar,
  MobileNavBarContainer,
  MenuItem,
  HeaderMainContainer,
  BlurDiv,
  MenuDropDownContainer,
  MenuDropDown,
  HeaderContainer,
  HeaderListContainer,
  HeaderButtonContainer,
};
