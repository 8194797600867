import React from "react";
import { useDispatch } from "react-redux";
import { onChangeLightHeader } from "../store/CommonStore";
import { PrivacyPolicyContainer } from "../style/privacyPolicy.style";
import { CommonString } from "../utils/CommonString";
import Loader from "../component/Loader";

export default function CookiePolicy() {
  const dispatch = useDispatch();
  dispatch(onChangeLightHeader(false));

  return (
    <>
      <Loader />
      <PrivacyPolicyContainer>
        <h1>{CommonString.cookie_policy}</h1>
        <p>
          E7Strategies Inc. ("we," "us," or "our") uses cookies and similar
          technologies on our website to improve user experience, provide IT
          consulting and staff augmentation services, and analyze site traffic.
          This Cookies Policy explains what cookies are, how we use them, and
          how you can manage them.
        </p>
        <p className="mt-1">
          <b>What Are Cookies?</b>
        </p>
        <p>
          Cookies are small text files stored on your device when you visit
          websites. They help the website remember information about your visit,
          such as your preferences and actions, to enhance your browsing
          experience. Cookies may be "session cookies," which are temporary and
          deleted once you close your browser, or "persistent cookies," which
          remain on your device for a longer period.
        </p>
        <p className="mt-1">
          <b>How We Use Cookies</b>
        </p>
        <p>We use cookies on our website for the following purposes:</p>
        <ul>
          <li>
            Essential Cookies: These cookies are necessary for the website to
            function properly. They allow you to navigate the site and use its
            features, such as accessing secure areas.
          </li>
          <li>
            Performance and Analytics Cookies: These cookies help us understand
            how visitors interact with our website by collecting information
            about traffic, user behavior, and any errors encountered. This data
            is used to improve the performance and functionality of our site.
          </li>
          <li>
            Functional Cookies: These cookies allow the website to remember your
            preferences and choices, such as language or region, providing a
            more personalized experience.
          </li>
          <li>
            Advertising and Targeting Cookies: We may use these cookies to
            deliver advertisements that are relevant to your interests, both on
            our website and on third-party websites. These cookies track your
            online activity to show you personalized ads.
          </li>
        </ul>
        <p className="mt-1">
          <b>Third-Party Cookies</b>
        </p>
        <p>
          We may partner with third-party service providers, such as analytics
          and advertising platforms, that use cookies on our website. These
          third parties may have their own cookie policies, and we encourage you
          to review them.
        </p>
        <p className="mt-1">
          <b>How to Manage Cookies</b>
        </p>
        <p>
          You can manage or disable cookies in your browser settings. Most
          browsers allow you to:
        </p>
        <ul>
          <li>View and delete cookies already stored on your device.</li>
          <li>Block the storage of cookies on your device.</li>
          <li>
            Set up notifications for when cookies are being placed on your
            device.
          </li>
        </ul>
        <p>
          Please note that disabling cookies may affect the functionality and
          performance of our website, and certain features may not work as
          intended.
        </p>
        <p className="mt-1">
          <b>Updates to This Cookies Policy</b>
        </p>
        <p>
          We may update this Cookies Policy periodically to reflect changes in
          our practices or relevant laws. Any updates will be posted on this
          page with the date of revision.
        </p>
        <p className="mt-1">
          <b>Contact Us</b>
        </p>
        <p>
          If you have any questions about our use of cookies or how we handle
          your data, please contact us via our Contact Us page.
        </p>
      </PrivacyPolicyContainer>
    </>
  );
}
