import React, { useEffect, useRef } from "react";
import { Grid } from "@mui/material";
import whoWeAreImg from "../../assets/images/whoWeAreBackgroundImg.svg";
import {
  WhoWeAreButton,
  WhoWeAreContainer,
  WhoWeAreTextContainer,
} from "../../style/home.style";
import { CommonString } from "../../utils/CommonString";
import MagneticHeaderButton from "../../component/Button";
import UseHover from "../../component/UseHover";
import { Color } from "../../utils/Color";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import gsap from "gsap";
import { onChangeActiveMenuStatus } from "../../store/CommonStore";
import { useDispatch } from "react-redux";
// Register the ScrollTrigger plugin
gsap.registerPlugin(ScrollTrigger);

export default function WhoWeAreSection() {
  const dispatch = useDispatch();
  const [hoverRef] = UseHover(); // Custom hook for hover effect
  const parentRef = useRef(null); // Ref for the parent container
  const textRef = useRef(null); // Ref for the text container
  const parent = useRef(0);
  useEffect(() => {
    if (!parentRef.current || !textRef.current) return; // Ensure the refs are available
    // Pin the parent component at the start and unpin at a specific point
    const scrollTrigger = gsap.to(parentRef.current, {
      scrollTrigger: {
        trigger: parentRef.current, // Pin based on this element
        start: "top top", // Start pinning when the top of the element hits the top of the viewport
        end: "+=1100", // End pinning after scrolling down 1000 pixels
        pin: true, // Enable pinning
        pinSpacing: true, // Maintain space where the element was originally
        scrub: true, // Smooth pinning as you scroll
      },
    });
    // Animate color change for each word
    const words = textRef.current.querySelectorAll(".word"); // Select all words
    gsap.fromTo(
      words,
      { color: "lightgrey" }, // Initial color (secondary text color)
      {
        color: Color.primary_color, // Final color (primary color)
        stagger: 0.2, // Stagger the animation for each word
        scrollTrigger: {
          trigger: parentRef.current, // Use the same trigger
          start: "top top", // Start the animation at the same time
          end: "+=1100", // Match the end scroll point
          scrub: true, // Smooth transition with scrolling
        },
      }
    );
    return () => {
      scrollTrigger.kill(); // Cleanup the ScrollTrigger instance on component unmount
    };
  }, []);

  // Split text into words
  const splitTextIntoWords = (text) => {
    return text.split(" ").map((word, index) => (
      <span
        key={index}
        className="word"
        style={{ display: "inline-block", marginRight: "8px" }}
      >
        {word}
      </span>
    ));
  };
  function onClick() {
    window.scrollTo(0, 0);
    dispatch(onChangeActiveMenuStatus(CommonString.about));
    localStorage.setItem("activeMenu", CommonString.about);
  }
  return (
    <>
      <div className="viewOnlyInLaptop">
        <WhoWeAreContainer ref={parentRef}>
          <img alt="" src={whoWeAreImg} />
          <Grid container spacing={4} className="innerContainer">
            <Grid item md={5} xs={12}></Grid>
            <Grid item md={7} xs={12}>
              <WhoWeAreTextContainer
                ref={textRef}
                style={{ color: Color.secondary_text_color }}
              >
                <p>{splitTextIntoWords(CommonString.who_we_are_text_1)}</p>
                <p className="mt-1">
                  {splitTextIntoWords(CommonString.who_we_are_text_2)}
                </p>
              </WhoWeAreTextContainer>
              <WhoWeAreButton>
                <MagneticHeaderButton
                  text={CommonString.know_more}
                  ref={hoverRef}
                  parent={parent}
                  onClick={() => onClick()}
                />
              </WhoWeAreButton>
            </Grid>
          </Grid>
        </WhoWeAreContainer>
      </div>
      <div className="viewOnlyInMobile">
        <WhoWeAreContainer>
          <img alt="" src={whoWeAreImg} />
          <Grid container columnSpacing={4} className="innerContainer">
            <Grid item md={5} xs={12}></Grid>
            <Grid item md={7} xs={12}>
              <WhoWeAreTextContainer
                style={{ color: Color.secondary_text_color }}
              >
                <h3 className="viewOnlyInMobile">
                  {CommonString.who_we_are_title}
                </h3>
                <p>{splitTextIntoWords(CommonString.who_we_are_text_1)}</p>
                <p className="mt-1">
                  {splitTextIntoWords(CommonString.who_we_are_text_2)}
                </p>
                <div className="mb-1">
                  <MagneticHeaderButton
                    text={CommonString.know_more}
                    onClick={() => onClick()}
                  />
                </div>
              </WhoWeAreTextContainer>
            </Grid>
          </Grid>
        </WhoWeAreContainer>
      </div>
    </>
  );
}
