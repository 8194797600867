import React, { useRef } from "react";
import { StaffAugmentationBannerContainer } from "../../../style/service.style";
import { BannerImg, BannerContainer } from "../../../style/common.style";
import { BannerText, BannerTitle } from "../../../style/home.style";
import { CommonString } from "../../../utils/CommonString";
import service_img_1 from "../../../assets/images/service_img_1.svg";
import MagneticHeaderButton from "../../../component/Button";
import UseHover from "../../../component/UseHover";
import { onChangeActiveMenuStatus } from "../../../store/CommonStore";
import { useDispatch } from "react-redux";
import service_img_2_mobile from "../../../assets/images/service_img_2_mobile.svg";
// import BannerContainer from "../../../style/common.style";
export default function StaffAugmentationBannerSection() {
  const dispatch = useDispatch();
  const [hoverRef] = UseHover();
  const parent = useRef(0);
  function onClick() {
    window.scrollTo(0, 0);
    dispatch(onChangeActiveMenuStatus(CommonString.contact));
    localStorage.setItem("activeMenu", CommonString.contact);
  }
  return (
    <StaffAugmentationBannerContainer>
      <BannerImg className="viewOnlyInLaptop" src={service_img_1} />
      <BannerImg className="viewOnlyInMobile" src={service_img_2_mobile} />
      <BannerContainer>
        <BannerTitle>{CommonString.service_1_title}</BannerTitle>
        <BannerText>{CommonString.staff_augmentation_text}</BannerText>
        <div className="mt-1">
          <MagneticHeaderButton
            isLightBackground={true}
            text={CommonString.lets_strategize}
            ref={hoverRef}
            parent={parent}
            onClick={() => onClick()}
          />
        </div>
      </BannerContainer>
    </StaffAugmentationBannerContainer>
  );
}
