import React from "react";
import { PrivacyPolicyContainer } from "../style/privacyPolicy.style";
import { CommonString } from "../utils/CommonString";
import { onChangeLightHeader } from "../store/CommonStore";
import { useDispatch } from "react-redux";
import Loader from "../component/Loader";
import { Link } from "react-router-dom";

export default function PrivacyPolicy() {
  const dispatch = useDispatch();
  dispatch(onChangeLightHeader(false));
  function onClick() {
    window.scrollTo({ top: 0, behavior: "smooth" });
  }

  return (
    <>
      <Loader />
      <PrivacyPolicyContainer>
        <h1>{CommonString.privacy_policy}</h1>
        <p>
          E7Strategies Inc. is committed to protecting your privacy and adhering
          to applicable data protection and privacy laws and regulations. This
          Privacy Policy outlines how E7Strategies collects, manages, and
          safeguards personal data in the course of its operations, as well as
          the rights individuals and organizations have regarding their data.
          Additional information may be provided through notices or supplements.
          In the event of any conflict between this Privacy Policy and such
          notices/supplements, the notices/supplements will take precedence. Any
          third-party partners or collaborators working with E7Strategies to
          deliver products or services may have their own privacy policies.
          E7Strategies is not responsible for the privacy practices of those
          third parties and encourages reviewing their privacy policies.
        </p>
        <p className="mt-1">
          <b>What Data Does E7Strategies Collect?</b>
        </p>
        <p>
          E7Strategies collects personal data both directly and from other
          legitimate sources as permitted by applicable laws. The data collected
          is strictly relevant and necessary for the purposes defined at the
          time of collection, and it is retained only for the period necessary
          to meet those purposes.
        </p>
        <p className="mt-1">
          <b>How Does E7Strategies Use the Data?</b>
        </p>
        <p>
          E7Strategies collects, stores, and processes personal data to conduct
          business operations, provide services, and comply with legal
          obligations. In some cases, personal data may be processed for
          additional purposes, subject to consent and compliance with applicable
          regulations. The data will be retained only as long as required for
          these purposes and deleted when no longer necessary.
        </p>
        <p className="mt-1">
          <b>Treatment of Sensitive Information</b>
        </p>
        <p>
          E7Strategies limits the collection of sensitive data and will only
          collect and process such information where legally justified or with
          explicit consent from the data owner. Sensitive information is treated
          with extra care, processed strictly in compliance with applicable
          laws, and accessible only to those individuals who have a legitimate
          need to know.
        </p>
        <p className="mt-1">
          <b>Who Has Access to the Data?</b>
        </p>
        <p>
          E7Strategies ensures that access to personal data is limited to
          authorized individuals who need the information for legitimate
          business purposes. Strict controls are in place to regulate access,
          ensuring that only the necessary data is available, with the remainder
          remaining protected and restricted.
        </p>
        <p className="mt-1">
          <b>Safeguarding Personal Data</b>
        </p>
        <p>
          E7Strategies prioritizes privacy and security, implementing
          comprehensive policies and guidelines to safeguard personal data.
          These efforts include proactive risk management, security and privacy
          engineering, regular training, and ongoing assessments. Measures are
          taken to address potential risks such as data loss, unauthorized
          access, or online threats, tailored to the sensitivity of the data
          being processed.
        </p>
        <p className="mt-1">
          <b>Data Quality</b>
        </p>
        <p>
          E7Strategies takes steps to maintain the accuracy of the personal data
          in its possession and deletes or corrects irrelevant or outdated data
          when identified. Public sources may be used to verify data as allowed
          or required by applicable law or based on consent.
        </p>
        <p className="mt-1">
          <b>Contact Information</b>
        </p>
        <p>
          For any inquiries regarding this Privacy Policy or data-related
          matters, please contact us via our{" "}
          <Link to={"/Contact"}>
            <b onClick={() => onClick()} style={{ cursor: "pointer" }}>
              Contact Us
            </b>{" "}
          </Link>
          page.
        </p>
      </PrivacyPolicyContainer>
    </>
  );
}
