import React, { useEffect, useRef } from "react";
import { OurStoryContainer } from "../../style/about.style";
import { CommonString } from "../../utils/CommonString";
import { Grid } from "@mui/material";
import whoWeAreImg from "../../assets/images/ourStoryBackgroundImg.svg";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import gsap from "gsap";
import { Color } from "../../utils/Color";
import { WhoWeAreTextContainer } from "../../style/home.style";
gsap.registerPlugin(ScrollTrigger);

export default function OurStorySection() {
  const parentRef = useRef(null); // Ref for the parent container
  const textRef = useRef(null); // Ref for the text container
  useEffect(() => {
    if (!parentRef.current || !textRef.current) return; // Ensure the refs are available
    // Pin the parent component at the start and unpin at a specific point
    const scrollTrigger = gsap.to(parentRef.current, {
      scrollTrigger: {
        trigger: parentRef.current, // Pin based on this element
        start: "top top", // Start pinning when the top of the element hits the top of the viewport
        end: "+=1000", // End pinning after scrolling down 1000 pixels
        pin: true, // Enable pinning
        pinSpacing: true, // Maintain space where the element was originally
        scrub: true, // Smooth pinning as you scroll
      },
    });
    // Animate color change for each word
    const words = textRef.current.querySelectorAll(".word"); // Select all words
    gsap.fromTo(
      words,
      { color: "lightgrey" }, // Initial color (secondary text color)
      {
        color: Color.primary_color, // Final color (primary color)
        stagger: 0.2, // Stagger the animation for each word
        scrollTrigger: {
          trigger: parentRef.current, // Use the same trigger
          start: "top top", // Start the animation at the same time
          end: "+=1000", // Match the end scroll point
          scrub: true, // Smooth transition with scrolling
        },
      }
    );
    return () => {
      scrollTrigger.kill(); // Cleanup the ScrollTrigger instance on component unmount
    };
  }, []);
  const splitTextIntoWords = (text) => {
    return text.split(" ").map((word, index) => (
      <span
        key={index}
        className="word"
        style={{ display: "inline-block", marginRight: "8px" }}
      >
        {word}
      </span>
    ));
  };

  return (
    <div style={{ background: Color.contact_background_color }}>
      <div className="viewOnlyInLaptop">
        <OurStoryContainer ref={parentRef}>
          <img alt="" src={whoWeAreImg} />
          <Grid container className="innerContainer">
            <Grid item md={5} xs={12}></Grid>
            <Grid item md={7} xs={12}>
              <WhoWeAreTextContainer
                ref={textRef}
                style={{ color: Color.secondary_text_color }}
              >
                <p>{splitTextIntoWords(CommonString.our_story_text_1)}</p>
                <p className="mt-1">
                  {splitTextIntoWords(CommonString.our_story_text_2)}
                </p>
                <p className="mt-1">
                  {splitTextIntoWords(CommonString.our_story_text_3)}
                </p>
                <p className="mt-1">
                  {splitTextIntoWords(CommonString.our_story_text_4)}
                </p>
              </WhoWeAreTextContainer>
            </Grid>
          </Grid>
        </OurStoryContainer>
      </div>
      <div className="viewOnlyInMobile">
        <OurStoryContainer>
          <img alt="" src={whoWeAreImg} />
          <Grid container className="innerContainer">
            <Grid item md={6}>
              <h2>{CommonString.our_story}</h2>
            </Grid>
            <Grid item md={6}>
              <WhoWeAreTextContainer
                style={{ color: Color.secondary_text_color }}
              >
                <p>{splitTextIntoWords(CommonString.our_story_text_1)}</p>
                <p className="mt-1">
                  {splitTextIntoWords(CommonString.our_story_text_2)}
                </p>
                <p className="mt-1">
                  {splitTextIntoWords(CommonString.our_story_text_3)}
                </p>
                <p className="mt-1">
                  {splitTextIntoWords(CommonString.our_story_text_4)}
                </p>
              </WhoWeAreTextContainer>
            </Grid>
          </Grid>
        </OurStoryContainer>
      </div>
    </div>
  );
}
