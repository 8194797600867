import React from "react";
import { ContractVehicleMainContainer } from "../../style/service.style";
import { Grid } from "@mui/material";
import CertificationsImg1 from "../../assets/images/CertificationsImg1.svg";
import CertificationsImg2 from "../../assets/images/CertificationsImg2.svg";
import CertificationsImg3 from "../../assets/images/CertificationsImg3.svg";

export default function Certifications() {
  return (
    <ContractVehicleMainContainer container spacing={3}>
      <Grid item md={12}>
        <h2>Certifications</h2>
      </Grid>
      <Grid item md={4} xs={12}>
        <div>
          <img src={CertificationsImg1} alt="" />
          <h4>Certified Business Enterprise (CBE) </h4>
          <ul class="custom-list">
            <li>Local Business Enterprise (LBE) </li>
            <li>Disadvantaged Business Enterprise (DBE) </li>
            <li>Small Business Enterprise (SBE) </li>
            <li>Development Enterprise Zone (DZE) </li>
            <li>Resident Owned Business (ROB) </li>
            <li>Equity Impact Enterprise (EIE) </li>
          </ul>
        </div>
      </Grid>
      <Grid item md={4} xs={12}>
        <div>
          <img src={CertificationsImg2} alt="" />
          <h4>
            Metropolitan Washington Airports Authority (MWAA) Small Local
            Business Enterprise (SLBE) 
          </h4>
        </div>
      </Grid>
      <Grid item md={4} xs={12}>
        <div>
          <img src={CertificationsImg3} alt="" />
          <h4>
            Women-Owned Small Businesses (WOSB) and Economically Disadvantaged
            Women-Owned Small Businesses (EDWOSB) 
          </h4>
        </div>
      </Grid>
    </ContractVehicleMainContainer>
  );
}
