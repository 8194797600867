import React, { useEffect } from "react";
import ContactBannerSection from "./ContactBannerSection";
import { useDispatch } from "react-redux";
import { onChangeLightHeader } from "../../store/CommonStore";
import Loader from "../../component/Loader";
// import CareerSection from "../home/CareerSection";

export default function ContactScreen() {
  const dispatch = useDispatch();
  useEffect(() => {
    window.scrollTo(0, 0);
    dispatch(onChangeLightHeader(true));
  }, []);

  return (
    <>
      <Loader />
      <ContactBannerSection />
    </>
  );
}
