import React, { useEffect } from "react";
import StaffAugmentationBannerSection from "./StaffAugmentationBannerSection";
import SubServiceSection from "./SubServiceSection";
import WhoWeSupportSection from "../../home/WhoWeSupportSection";
import NaicsCodeSection from "../NaicsCodes";
import StaffAugmentationProcess from "./StaffAugmentationProcess";
import ContractVehicles from "../ContractVehicles";
import Loader from "../../../component/Loader";
import { useDispatch } from "react-redux";
import { onChangeLightHeader } from "../../../store/CommonStore";

export default function StaffAugmentationScreen() {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(onChangeLightHeader(true));
  }, []);

  return (
    <>
      <Loader />
      <StaffAugmentationBannerSection />
      <SubServiceSection />
      <WhoWeSupportSection />
      <ContractVehicles />
      <NaicsCodeSection />
      <StaffAugmentationProcess />
    </>
  );
}
