// src/components/ScrollToTop.js
import { useEffect } from "react";
import { useLocation } from "react-router-dom";

const ScrollToTop = () => {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0); // Scroll to the top whenever the pathname changes
  }, [pathname]); // Dependency on pathname ensures this runs every time the route changes

  return null; // This component doesn't render anything, it just performs side effects
};

export default ScrollToTop;
