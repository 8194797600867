import React from "react";
import contactImg from "../../assets/images/contactImg.svg";
import ContactMobileImg from "../../assets/images/ContactMobileImg.svg";
import ContactForm from "./ContactForm";
import { StaffAugmentationBannerContainer } from "../../style/service.style";
import { BannerContainer, BannerImg } from "../../style/common.style";

export default function ContactBannerSection() {
  return (
    <StaffAugmentationBannerContainer>
      <BannerImg className="viewOnlyInLaptop" src={contactImg} />
      <BannerImg className="viewOnlyInMobile" src={ContactMobileImg} />
      <BannerContainer>
        <ContactForm />
      </BannerContainer>
    </StaffAugmentationBannerContainer>
  );
}
