import { Grid } from "@mui/material";
import React from "react";
import ContractVehicleImg1 from "../../assets/images/ContractVehicleImg1.svg";
import ContractVehicleImg2 from "../../assets/images/ContractVehicleImg2.svg";
import ContractVehicleImg3 from "../../assets/images/ContractVehicleImg3.svg";
import ContractVehicleImg4 from "../../assets/images/ContractVehicleImg4.svg";
import ContractVehicleImg5 from "../../assets/images/ContractVehicleImg5.svg";
import { ContractVehicleMainContainer } from "../../style/service.style";

export default function ContractVehicles() {
  return (
    <ContractVehicleMainContainer container spacing={3}>
      <Grid item md={12}>
        <h2>
          Contract <span>Vehicles</span>
        </h2>
      </Grid>
      <Grid item md={4} xs={12}>
        <div>
          <img src={ContractVehicleImg1} alt="" />
          <h4>DC Supply Schedule (DCSS) </h4>
          <ul class="custom-list">
            <li>Temporary Support Services</li>
            <li>Mission Oriented Business Integrated Services (MOBIS)</li>
            <li>Information Technology Equipment & Software</li>
          </ul>
        </div>
      </Grid>
      <Grid item md={4} xs={12}>
        <div>
          <img src={ContractVehicleImg2} alt="" />
          <h4>DC STaR and STaR2 (Supplier) </h4>
        </div>
      </Grid>
      <Grid item md={4} xs={12}>
        <div>
          <img src={ContractVehicleImg3} alt="" />
          <h4>
            MJUD Master Contract – Staff Augmentation & Professional Consulting
            Services 
          </h4>
        </div>
      </Grid>
      <Grid item md={4} xs={12}>
        <div>
          <img src={ContractVehicleImg4} alt="" />
          <h4>Louisiana Statewide IT Staffing Support</h4>
        </div>
      </Grid>
      <Grid item md={4} xs={12}>
        <div>
          <img src={ContractVehicleImg5} alt="" />
          <h4>
            Florida State Term Contract - Information Technology Staff
            Augmentation 
          </h4>
        </div>
      </Grid>
    </ContractVehicleMainContainer>
  );
}
