import styled from "styled-components";
import { CommonString } from "../utils/CommonString";
import { Color } from "../utils/Color";

const PrivacyPolicyContainer = styled.div`
  padding: ${CommonString.common_screen_padding};
  /* padding: 20vh; */
  background-color: ${Color.contact_background_color};
  color: ${Color.primary_color};
  p {
    line-height: 1.8rem;
  }
  li {
    line-height: 1.8rem;
    letter-spacing: 1px;
  }
  @media (${CommonString.mobile_screen}) {
    margin-top: 4vh;
  }
`;

export { PrivacyPolicyContainer };
