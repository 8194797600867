import React from "react";
import "./App.css";
import HomeScreen from "./screen/home/HomeScreen";
import AboutScreen from "./screen/about/AboutScreen";
import ContactScreen from "./screen/contact/ContactScreen";
import CookiePolicy from "./screen/CookiePolicy";
import PrivacyPolicy from "./screen/PrivacyPolicy";
import StaffAugmentationScreen from "./screen/services/staffAugmentation/StaffAugmentationScreen";
import ITConsultingScreen from "./screen/services/ITConsulting/ITConsultingScreen";
import { Route, Routes } from "react-router-dom";

export default function CustomRoutes() {
  return (
    <Routes>
      <Route path="/" exact element={<HomeScreen />} />
      <Route path="/About" element={<AboutScreen />} />
      <Route path="/Contact" element={<ContactScreen />} />
      <Route path="/CookiePolicy" element={<CookiePolicy />} />
      <Route path="/PrivacyPolicy" element={<PrivacyPolicy />} />
      <Route path="/StaffAugmentation" element={<StaffAugmentationScreen />} />
      <Route path="/ITConsulting" element={<ITConsultingScreen />} />
    </Routes>
  );
}
