import styled from "styled-components";
import { Color } from "../utils/Color";
import { CommonString } from "../utils/CommonString";

const BannerContainer = styled.div`
  height: 100vh;
  overflow: hidden;
  background-repeat: no-repeat;
  background-size: cover;
  display: flex;
  justify-content: center;
  flex-direction: column;
  div:first {
    max-width: 980px;
  }
  img {
    object-fit: fill;
    width: -webkit-fill-available;
    top: 0;
  }
  a {
    margin-top: 10%;
  }
  @media (${CommonString.mobile_screen}) {
    height: auto;
  }
`;
const BannerInnerContainer = styled.div`
  position: absolute;
  top: 30vh;
  @media (${CommonString.mobile_screen}) {
    position: initial;
  }
`;
//core value section
const CoreValueContainer = styled.div`
  height: 100vh;
  overflow: hidden;
  @media (${CommonString.mobile_screen}) {
    height: auto;
    overflow: auto;
  }
`;
const CoreValueText = styled.h1`
  writing-mode: vertical-lr;
  rotate: -180deg;
  color: ${Color.primary_color};
  margin-block-start: 0;
  margin-block-end: 0;
  margin-top: 5.4vmax;
  margin-left: 1.9vmax;
  @media (${CommonString.mobile_screen}) {
    rotate: 180deg;
    writing-mode: initial;
  }
`;
const CoreValueInnerContainer = styled.div`
  height: 100vh;
  background-color: ${(props) => props.color};
  color: ${(props) =>
    props.color === Color.secondary_color
      ? Color.primary_color
      : Color.secondary_color};
  padding: 2vmax;
  position: relative;
  overflow: hidden;
  h4 {
    line-height: 2.5rem;
    margin-bottom: 15px;
    font-size: 1.8rem;
    margin-top: ${(props) =>
      props.margin
        ? 0
        : props.color === Color.secondary_color
        ? "30vh"
        : "10vh"};
  }
  img {
    transition: all 0.7s ease-out;
    position: absolute;
    left: -8vw;
    width: 60vh;
    bottom: ${(props) =>
      props.color === Color.secondary_color ? "inherit" : 0};
  }
  p {
    line-height: 1.3rem;
    font-weight: 600;
    letter-spacing: 0.7px;
    /* opacity: 0; */
  }
  transition: all 0.7s ease-out;
  flex: 1;
  &:hover {
    /* flex-grow: 1.2; */
    /* h4 {
      margin-top: ${(props) => (props.margin ? 0 : "10vh")};
    } */
    p {
      opacity: 1;
    }
    img {
      left: 0;
    }
  }
  @media (${CommonString.mobile_screen}) {
    height: 80vh;
    width: fit-content;
    overflow: hidden;
    display: ${(props) => (props.mobileView ? "none" : "inherit")};
    flex-direction: column;
    &:hover {
      flex-grow: 1;
      img {
        left: ${(props) =>
          props.color === Color.secondary_color ? 0 : "auto"};
        right: ${(props) =>
          props.color !== Color.secondary_color ? 0 : "auto"};
      }
    }
    img {
      bottom: 0;
      width: 40vh;
      left: ${(props) => (props.color === Color.secondary_color ? 0 : "auto")};
      right: ${(props) => (props.color !== Color.secondary_color ? 0 : "auto")};
    }
    h4 {
      margin-top: 0;
      padding: 3vh;
      text-align: center;
    }
    p {
      padding: 0;
      padding-bottom: 3vh;
      text-align: center;
    }
  }
`;
const CoreValueTitleForMobileView = styled.div`
  display: none;
  @media (${CommonString.mobile_screen}) {
    display: inherit;
    font-size: calc(var(--responsive-font-size) * 3);
    padding: 0 ${CommonString.common_screen_padding};
    /* text-align: center; */
    width: 100%;
  }
`;
const CoreValueFlexContainer = styled.div`
  display: flex;
  transition: all 0.7s ease-out;
  @media (${CommonString.mobile_screen}) {
    flex-direction: column;
    flex-wrap: wrap;
  }
`;
//our story section
const OurStoryContainer = styled.div`
  height: 100vh;
  padding: 0 ${CommonString.common_screen_padding};
  background-color: ${Color.contact_background_color};
  overflow: hidden;
  position: relative;
  .innerContainer {
    padding-top: 17vh;
    position: relative;
    z-index: 1;
  }
  p {
    margin-top: 2vh;
    font-size: 1.05rem;
    line-height: 1.8rem;
  }
  img:first-child {
    height: 52vw;
    position: absolute;
    bottom: -14vh;
    z-index: 0;
    left: 0;
  }
  @media (${CommonString.mobile_screen}) {
    height: auto;
    img:first-child {
      margin-top: 20px;
      margin-right: ${CommonString.common_screen_padding};
      display: none;
    }
    .innerContainer {
      padding-top: ${CommonString.common_screen_padding};
      padding-bottom: ${CommonString.common_screen_padding};
    }
  }
`;
const OurMissionContainer = styled.div`
  padding: ${CommonString.common_screen_padding};
  background-color: ${Color.primary_color};
  color: ${Color.secondary_color};
  height: 50vh;
  display: flex;
  flex-direction: column;
  position: relative;
  overflow: hidden;
  justify-content: space-between;
  h2 {
    margin: 0;
  }
  p {
    margin-top: 7vh;
    line-height: 1.7rem;
    letter-spacing: 0.015rem;
  }
  img {
    position: absolute;
    top: -10vh;
    right: -4vw;
    width: 70%;
  }
  @media (${CommonString.mobile_screen}) {
    height: auto;
  }
`;
const OurVisionContainer = styled.div`
  padding: ${CommonString.common_screen_padding};
  background-color: ${Color.secondary_color};
  color: ${Color.primary_color};
  height: 50vh;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  position: relative;
  overflow: hidden;
  h2 {
    margin: 0;
  }
  p {
    margin-top: 7vh;
    line-height: 1.7rem;
    letter-spacing: 0.015rem;
  }
  img {
    position: absolute;
    top: -10vh;
    right: -4vw;
    width: 60%;
  }
  @media (${CommonString.mobile_screen}) {
    height: auto;
  }
`;
export {
  OurVisionContainer,
  OurMissionContainer,
  CoreValueFlexContainer,
  OurStoryContainer,
  CoreValueTitleForMobileView,
  CoreValueInnerContainer,
  BannerContainer,
  BannerInnerContainer,
  CoreValueContainer,
  CoreValueText,
};
