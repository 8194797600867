import { Grid } from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import { CommonString } from "../../../utils/CommonString";
import { ServiceCardContainer } from "../../../style/service.style";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import gsap from "gsap";
import { Color } from "../../../utils/Color";
import { useDispatch } from "react-redux";
import { onChangeLightHeader } from "../../../store/CommonStore";
gsap.registerPlugin(ScrollTrigger);
let mm = gsap.matchMedia();

export default function SubServiceSection() {
  const dispatch = useDispatch();
  const [scrollIndex, setScrollIndex] = useState(0);
  const [isActive, setIsActive] = useState(false);
  const containerRef = useRef(null);
  const leftColRef = useRef(null);
  const rightColRef = useRef(null);

  function SubSectionCard(props) {
    return (
      <ServiceCardContainer light={props.light} background={props.background}>
        <div>
          <h4>{props.title}</h4>
          <p>{props.text}</p>
        </div>
      </ServiceCardContainer>
    );
  }

  useEffect(() => {
    mm.add("(min-width: 800px)", () => {
      gsap.to(leftColRef.current, {
        yPercent: 0,
        ease: "none",
        scrollTrigger: {
          trigger: containerRef.current,
          start: "top top",
          end: "bottom 500",
          onLeave: function () {
            setIsActive(false);
          },
          onEnter: function () {
            setIsActive(true);
          },
          onEnterBack: function () {
            setIsActive(true);
          },
          scrub: true,
        },
      });
      gsap.to(rightColRef.current, {
        yPercent: 20,
        ease: "none",
        scrollTrigger: {
          trigger: containerRef.current,
          start: "top top",
          end: "bottom bottom",
          scrub: true,
        },
      });
    });
  }, []);

  return (
    <Grid
      container
      spacing={2}
      className="p-4"
      style={{ background: Color.contact_background_color }}
    >
      <Grid item md={4} xs={12}>
        <div
          className="viewOnlyInLaptop"
          style={{
            position: isActive ? "sticky" : "inherit",
            top: isActive ? "12vh" : "auto",
            color: Color.primary_color,
          }}
        >
          <h2>{CommonString.services}</h2>
          <h2>
            <span>{CommonString.we_provide}</span>
          </h2>
        </div>
        <h2 className="viewOnlyInMobile">
          {CommonString.services} <span>{CommonString.we_provide}</span>
        </h2>
      </Grid>
      <Grid item md={8} className="subServiceCardContainer">
        <Grid container columnSpacing={6}>
          <Grid item md={6} xs={12} ref={leftColRef}>
            <SubSectionCard
              background={Color.primary_color}
              title={CommonString.IT_sub_service_1}
              text={CommonString.IT_sub_service_1_text}
            />
            <SubSectionCard
              background={Color.background_1}
              title={CommonString.IT_sub_service_2}
              text={CommonString.IT_sub_service_2_text}
            />
            <SubSectionCard
              light={true}
              background={Color.background_2}
              title={CommonString.IT_sub_service_3}
              text={CommonString.IT_sub_service_3_text}
            />
            <SubSectionCard
              light={true}
              background={Color.background_3}
              title={CommonString.IT_sub_service_4}
              text={CommonString.IT_sub_service_4_text}
            />
            <SubSectionCard
              light={true}
              background={Color.background_2}
              title={CommonString.IT_sub_service_5}
              text={CommonString.IT_sub_service_5_text}
            />
            <SubSectionCard
              background={Color.background_1}
              title={CommonString.IT_sub_service_6}
              text={CommonString.IT_sub_service_6_text}
            />
            <SubSectionCard
              background={Color.primary_color}
              title={CommonString.IT_sub_service_7}
              text={CommonString.IT_sub_service_7_text}
            />
          </Grid>
          <Grid item md={6} xs={12} ref={rightColRef}>
            <SubSectionCard
              background={Color.background_4}
              title={CommonString.IT_sub_service_8}
              text={CommonString.IT_sub_service_8_text}
            />
            <SubSectionCard
              light={true}
              background={Color.background_5}
              title={CommonString.IT_sub_service_9}
              text={CommonString.IT_sub_service_9_text}
            />
            <SubSectionCard
              light={true}
              background={Color.background_6}
              title={CommonString.IT_sub_service_10}
              text={CommonString.IT_sub_service_10_text}
            />
            <SubSectionCard
              light={true}
              background={Color.background_5}
              title={CommonString.IT_sub_service_11}
              text={CommonString.IT_sub_service_11_text}
            />
            <SubSectionCard
              background={Color.background_4}
              title={CommonString.IT_sub_service_12}
              text={CommonString.IT_sub_service_12_text}
            />
          </Grid>
        </Grid>
        {/* <Grid container spacing={5}>
          
          <SubSectionCard
            title={"Database/storage management"}
            text={
              "Efficiently organize, secure, and optimize data storage for seamless operations."
            }
          />
        </Grid> */}
      </Grid>
    </Grid>
  );
}
